export const fee_pdfs=[
    {
        "id":"1",
        "program":"Regular B.E.",
        "pdf":"https://drive.google.com/file/d/1La3rb_Z_6ki6qQPzI4yOV0aUYy3zTJlE/view?usp=sharing"
    },
    {
        "id":"2",
        "program":"Lateral B.E.",
        "pdf":"https://drive.google.com/file/d/1VAR9OrlUlK1K2RCy868rQmpq99-uQD5l/view?usp=sharing"
    },
    {
        "id":"3",
        "program":" Regular M.E.",
        "pdf":"https://drive.google.com/file/d/1b8bmXH0o3BQmDa7PSXIM63Kk6ZJUhOcP/view?usp=drive_link"
    },
    {
        "id":"4",
        "program":"Part Time B.E.",
        "pdf":"https://drive.google.com/file/d/1Zf2gNwS9hyOxBe6VF1r9_hndrutaIMDd/view?usp=drive_link"
    },
]