export const naac_data=[
    // {
    //     'id':3,
    //     'program':'Policy for Promotion of Research',
    //     'pdf':'https://drive.google.com/file/d/1HqGMXqR73D7ip0Qk_ZWH-89RS8DMgHMH/view?usp=sharing',
    // },
    {
        'id':2,
        'program':'NAAC Committee Members AY 2023-25',
        'pdf':'https://drive.google.com/file/d/1s9kwnaP-XOg_KIbGmCPYLpbwaFAq1ycf/view?usp=sharing',
    },
    {
        'id':1,
        'program':'SSR 2019',
        'pdf':'https://drive.google.com/file/d/16mZkLmHrTUkolXDtJS8mQLO3GAcgXuhC/view?usp=sharing',
    },
]
export const naac_menu=[
    {
        id: "1",
        path: "/naacbestpractices",
        link: "Best Practices",
        arl: "l",
    }
]
export const naac=[
    {
        'id':'1',
        'program':'2024-2025',
        'pdf':'https://docs.google.com/document/d/1Oa1BR6YPA2B-CGFcjkzW9wPWwj_xAg9IL6E7gKxZUMk/edit?usp=sharing'
    },
    {
        'id':'2',
        'program':'Best Practices 24-25',
        'pdf':'https://docs.google.com/document/d/1osI0LGC9WXPXEaUaEnqMBsKLp_7KLSbJ-YgoU7wLkKA/edit?usp=sharing'
    },
]