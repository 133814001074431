// Sports_heroimage_carousalinfo
// import image1 from "../assets/pictures/nss/nsshero1.webp";
// import image2 from "../assets/pictures/nss/nsshero2.webp";
// import image3 from "../assets/pictures/nss/nsshero3.webp";
// import image4 from "../assets/pictures/nss/nsshero4.webp";

// import ignatz from "../assets/pictures/sportsmeet/ignatz.png"
// import typhonz from "../assets/pictures/sportsmeet/Typhonz.png"
// import akkadians from "../assets/pictures/sportsmeet/AKKADIANS.png"
// import rising from "../assets/pictures/sportsmeet/rising.png"



// const Sports_heroimage_carousalinfo = {
//   id: 1,
//   title: "Sports",
//   img_list: [image1, image2, image3, image4],
// };

// import basketball_M_R from "../assets/pictures/sportsmeet/phoenix/BB_M(R).webp"
// import basketball_W_R from "../assets/pictures/sportsmeet/phoenix/BB_W(R).webp"
// import volleyball_M_R from "../assets/pictures/sportsmeet/phoenix/VB_M(R).webp"
// import handball_M_R from "../assets/pictures/sportsmeet/phoenix/Hand_M(R).webp"
// import football_M_W from "../assets/pictures/sportsmeet/phoenix/Foot_M.webp"
// import kabaddi_M_W from "../assets/pictures/sportsmeet/phoenix/Kabaddi_M.webp"
// import handball_W_W from "../assets/pictures/sportsmeet/phoenix/Hand_W.webp"
// import ballbadminton_W_R from "../assets/pictures/sportsmeet/phoenix/Ball_bad_W(R).webp"

// import cricket_M_R from "../assets/pictures/sportsmeet/akkadians/Cric_M(R).webp"
// import khokho_W_R from "../assets/pictures/sportsmeet/akkadians/Kho_W(R).webp"
// import badminton_W_R from "../assets/pictures/sportsmeet/akkadians/Bad_W(R).webp"

// import badminton_W_W from "../assets/pictures/sportsmeet/ignatz/Badminton_W.webp"
// import badminton_M_W from "../assets/pictures/sportsmeet/ignatz/Badminton_M.webp"
// import hockey_M_W from "../assets/pictures/sportsmeet/ignatz/Hockey_M.webp"
// import khokho_M_W from "../assets/pictures/sportsmeet/ignatz/Kho_M.webp"
// import volleyball_M_W from "../assets/pictures/sportsmeet/ignatz/VB_M.webp"
// import volleyball_W_W from "../assets/pictures/sportsmeet/ignatz/VB_W.webp"
// import basketball_W_W from "../assets/pictures/sportsmeet/ignatz/BB_W.webp"
// import ballbadminton_W_W from "../assets/pictures/sportsmeet/ignatz/Ball_bad_W.webp"
// import khokho_W_W from "../assets/pictures/sportsmeet/ignatz/Kho_W.webp"
// import football_M_R from "../assets/pictures/sportsmeet/ignatz/Foot_M(R).webp"
// import ballbadminton_M_R from "../assets/pictures/sportsmeet/ignatz/Ball_bad_M(R).webp"
// import chess_M_R from "../assets/pictures/sportsmeet/ignatz/Chess_M(R).webp"

// import badminton_M_R from "../assets/pictures/sportsmeet/typhonz/Badminton_M(R).webp"
// import khokho_M_R from "../assets/pictures/sportsmeet/typhonz/Kho_M(R).webp"
// import ballbadminton_M_W from "../assets/pictures/sportsmeet/typhonz/Ball_bad_M.webp"
// import basketball_M_W from "../assets/pictures/sportsmeet/typhonz/BB_M.webp"
// import cricket_M_W from "../assets/pictures/sportsmeet/typhonz/Cric_M.webp"
// import Hockey_M_R from "../assets/pictures/sportsmeet/typhonz/Hockey_M(R).webp"
// import Handball_W_R from "../assets/pictures/sportsmeet/typhonz/Hand_W(R).webp"
// import Handball_M_W from "../assets/pictures/sportsmeet/typhonz/Hand_M.webp"
// import kabaddi_M_R from "../assets/pictures/sportsmeet/typhonz/Kabaddi_M(R).webp"
// import volleyball_W_R from "../assets/pictures/sportsmeet/typhonz/VB_W(R).webp"
// import chess_M_W from "../assets/pictures/sportsmeet/typhonz/Chess_M.webp"

import vimanthan from "../assets/pictures/vimanthan.webp"
import raveena from "../assets/pictures/raveena.webp"
import chess from "../assets/pictures/sports chess.webp"
import baseketball_CM from "../assets/pictures/basketballimg.webp"
// import subha from "../assets/pictures/subhachess.webp"
import swimming from "../assets/pictures/swimming_anna.jpg"
import subha from "../assets/pictures/subha chess.jpg"
import badmittongirls from "../assets/pictures/ballbadmittongirls.jpg"
import hockeyboys from "../assets/pictures/hockeyboys.jpg"
const badminton_M_R="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/cocurricular/Sports/Sportsmeet/Typhonz/Badminton_M(R).webp"
const khokho_M_R="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/cocurricular/Sports/Sportsmeet/Typhonz/Kho_M(R).webp"
const ballbadminton_M_W="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/cocurricular/Sports/Sportsmeet/Typhonz/Ball_bad_M.webp"
const basketball_M_W="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/cocurricular/Sports/Sportsmeet/Typhonz/BB_M.webp"
const cricket_M_W="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/cocurricular/Sports/Sportsmeet/Typhonz/Cric_M.webp"
const Hockey_M_R="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/cocurricular/Sports/Sportsmeet/Typhonz/Hockey_M(R).webp"
const Handball_W_R="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/cocurricular/Sports/Sportsmeet/Typhonz/Hand_W(R).webp"
const Handball_M_W="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/cocurricular/Sports/Sportsmeet/Typhonz/Hand_M.webp"
const kabaddi_M_R="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/cocurricular/Sports/Sportsmeet/Typhonz/Kabaddi_M(R).webp"
const volleyball_W_R="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/cocurricular/Sports/Sportsmeet/Typhonz/VB_W(R).webp"
const chess_M_W="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/cocurricular/Sports/Sportsmeet/Typhonz/Chess_M.webp"

const badminton_W_W="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/cocurricular/Sports/Sportsmeet/Ignatz/Badminton_W.webp"
const badminton_M_W="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/cocurricular/Sports/Sportsmeet/Ignatz/Badminton_M.webp"
const hockey_M_W="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/cocurricular/Sports/Sportsmeet/Ignatz/Hockey_M.webp"
const khokho_M_W="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/cocurricular/Sports/Sportsmeet/Ignatz/Kho_M.webp"
const volleyball_M_W="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/cocurricular/Sports/Sportsmeet/Ignatz/VB_M.webp"
const volleyball_W_W="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/cocurricular/Sports/Sportsmeet/Ignatz/VB_W.webp"
const ballbadminton_W_W="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/cocurricular/Sports/Sportsmeet/Ignatz/Ball_bad_W.webp"
const basketball_W_W="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/cocurricular/Sports/Sportsmeet/Ignatz/BB_W.webp"
const khokho_W_W="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/cocurricular/Sports/Sportsmeet/Ignatz/Kho_W.webp"
const football_M_R="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/cocurricular/Sports/Sportsmeet/Ignatz/Foot_M(R).webp"
const ballbadminton_M_R="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/cocurricular/Sports/Sportsmeet/Ignatz/Ball_bad_M(R).webp"
const chess_M_R="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/cocurricular/Sports/Sportsmeet/Ignatz/Chess_M(R).webp"

const badminton_W_R ="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/cocurricular/Sports/Sportsmeet/Akkadians/Bad_W(R).webp"
const cricket_M_R="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/cocurricular/Sports/Sportsmeet/Akkadians/Cric_M(R).webp"
const khokho_W_R="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/cocurricular/Sports/Sportsmeet/Akkadians/Kho_W(R).webp"

const basketball_M_R="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/cocurricular/Sports/Sportsmeet/Phoenix/BB_M(R).webp"
const basketball_W_R="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/cocurricular/Sports/Sportsmeet/Phoenix/BB_W(R).webp"
const volleyball_M_R="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/cocurricular/Sports/Sportsmeet/Phoenix/VB_M(R).webp"
const handball_M_R="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/cocurricular/Sports/Sportsmeet/Phoenix/Hand_M(R).webp"
const football_M_W="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/cocurricular/Sports/Sportsmeet/Phoenix/Foot_M.webp"
const kabaddi_M_W="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/cocurricular/Sports/Sportsmeet/Phoenix/Kabaddi_M.webp"
const handball_W_W="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/cocurricular/Sports/Sportsmeet/Phoenix/Hand_W.webp"
const ballbadminton_W_R="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/cocurricular/Sports/Sportsmeet/Phoenix/Ball_bad_W(R).webp"

const akkadians="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/cocurricular/Sports/Sportsmeet/Akkadians/AKKADIANS.webp"
const rising="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/cocurricular/Sports/Sportsmeet/Phoenix/rising.webp"
const ignatz="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/cocurricular/Sports/Sportsmeet/Ignatz/ignatz.webp"
const typhonz="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/cocurricular/Sports/Sportsmeet/Typhonz/Typhonz.webp"

const image1="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/sports/boys+ball+badminton.webp"
const image2="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/sports/boys+volleyball.webp"
const image3="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/sports/cricket.webp"
const image4="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/sports/girls+badminton.webp"
const image5="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/sports/girls+volleyball.webp"

const baja ="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Mech/SAE/BAJA-2014.webp"

const sports_slideshow=[badmittongirls,hockeyboys,baseketball_CM,chess,image5]

const sportstime = [
  {
    id:1,
  head:'POE 1',
  house:'left',
  content:'Excel in professional career and or higher education by acquiring knowledge and also in inter disciplinary domains.'
  },
  {
    id:2,
  head:'POE 1',
  house:'right',
  content:'Excel in professional career and or higher education by acquiring knowledge and also in inter disciplinary domains.'
  }
]

const akkadians_wins={
    winners:[],
    runners:[
        "Women's Badminton"
    ]
}

const sportslist = [
  {
    id:1,
    point:'sncsdbchabv.kjshvekrjvnejvbsnd avd kqvjafn,vn sdcvd ,mdmb xz ndn jdfbvdbvhjgdsvvjhvhk,vnhdsvnmvnf bek'
  },
  {
    id:2,
    point:'belcy'
  }
]

const sportsvision = {
  id: 1,
  visiondata:'The Vision of CSE department is committed to continually improve educational environment to developgraduates having strong Academic and Technical knowledge required to achieve excellence in their profession and career.'
};

const sportsmission =[
  {
    id:'1',
    mission:'To provide excellent graduate education and to prepare students for careers as scientists,industrialist, academician, researcher and developer to evolve to their innovative ideas and applications.'
  },
  {
    id:'2',
    mission:'To inculcate professional behavior, strong ethical values and leadership abilities in the young minds so as to work with a commitment to the progress of our Nation.'
  },
]


const sports_meet_2k24_schedule=[
    {
        'id':'1',
        'date':'MAR 05 2024',
        'title':"Ball Badminton (Men)",
        'time':'06:30 am',
        'venue':'Ball Badminton Court',
        'details':'Witness ACMARA RIVALRY\'24',
        // 'status':'due'
    },
    {
        'id':'2',
        'date':'MAR 05 2024',
        'title':"Badminton (Women)",
        'time':'06:30 am',
        'venue':'Indoor Court',
        'details':'Witness ACMARA RIVALRY\'24',
        // 'status':'due'
    },
    {
        'id':'3',
        'date':'MAR 06 2024',
        'title':"Ball Badminton (Women)",
        'time':'06:30 am',
        'venue':'Ball Badminton Court',
        'details':'Witness ACMARA RIVALRY\'24',
        // 'status':'due'
    },
    {
        'id':'4',
        'date':'MAR 08 2024',
        'title':"Basketball (Men)",
        'time':'06:30 am',
        'venue':'Basketball Court',
        'details':'Witness ACMARA RIVALRY\'24',
        // 'status':'due'
    },
    {
        'id':'5',
        'date':'MAR 09 2024',
        'title':"Badminton (Men)",
        'time':'06:30 am',
        'venue':'Indoor Court',
        'details':'Witness ACMARA RIVALRY\'24',
        // 'status':'due'
    },
    {
        'id':'6',
        'date':'MAR 09 2024',
        'title':"Hockey (Men)",
        'time':'06:30 am',
        'venue':'Playground',
        'details':'Witness ACMARA RIVALRY\'24',
        // 'status':'due'
    },
    {
        'id':'7',
        'date':'MAR 10 2024',
        'title':"Cricket (Men)",
        'time':'06:30 am',
        'venue':'Playground',
        'details':'Witness ACMARA RIVALRY\'24',
        // 'status':'due'
    },
    {
        'id':'8',
        'date':'MAR 11 2024',
        'title':"Volleyball (Men)",
        'time':'06:30 am',
        'venue':'Volleyball Court',
        'details':'Witness ACMARA RIVALRY\'24',
        // 'status':'due'
    },
    {
        'id':'9',
        'date':'MAR 11 2024',
        'title':"Basketball (Women)",
        'time':'06:30 am',
        'venue':'Basketball Court',
        'details':'Witness ACMARA RIVALRY\'24',
        // 'status':'due'
    },
    {
        'id':'10',
        'date':'MAR 12 2024',
        'title':"Volleyball (Women)",
        'time':'06:30 am',
        'venue':'Volleyball Court',
        'details':'Witness ACMARA RIVALRY\'24',
        // 'status':'due'
    },
    {
        'id':'13',
        'date':'MAR 12 2024',
        'title':"Kho-Kho (Men)",
        'time':'06:30 am',
        'venue':'Kho-Kho Court',
        'details':'Witness ACMARA RIVALRY\'24',
        // 'status':'due'
    },
    {
        'id':'11',
        'date':'MAR 13 2024',
        'title':"Handball (Men)",
        'time':'06:30 am',
        'venue':'Handball Court',
        'details':'Witness ACMARA RIVALRY\'24',
        // 'status':'due'
    },
    {
        'id':'14',
        'date':'MAR 13 2024',
        'title':"Kho-Kho (Women)",
        'time':'06:30 am',
        'venue':'Kho-Kho Court',
        'details':'Witness ACMARA RIVALRY\'24',
        // 'status':'due'
    },
    {
        'id':'12',
        'date':'MAR 14 2024',
        'title':"Handball (Women)",
        'time':'06:30 am',
        'venue':'Handball Court',
        'details':'Witness ACMARA RIVALRY\'24',
        // 'status':'due'
    },
    {
        'id':'15',
        'date':'MAR 18 2024',
        'title':"Kabaddi (Men)",
        'time':'06:30 am',
        'venue':'Kabaddi Court',
        'details':'Witness ACMARA RIVALRY\'24',
        // 'status':'due'
    },
    {
        'id':'16',
        'date':'MAR 19 2024',
        'title':"Football (Men)",
        'time':'06:30 am',
        'venue':'Playground',
        'details':'Witness ACMARA RIVALRY\'24',
        // 'status':'due'
    },
    {
        'id':'17',
        'date':'MAR 26 2024',
        'title':"Athletics",
        'time':'06:30 am',
        'venue':'Playground',
        'details':'Witness ACMARA RIVALRY\'24',
        // 'status':'due'
    },
]

const ignatz_list={
        winners:[
            {
                id:1,
                head:"Women's Badminton",
                src:badminton_W_W,
            },
            {
                id:2,
                head:"Women's Ball Badminton",
                src:ballbadminton_W_W,
            },
            {
                id:3,
                head:"Men's Badminton",
                src:badminton_M_W,
            },
            {
                id:4,
                head:"Men's Hockey",
                src:hockey_M_W,
            },
            {
                id:5,
                head:"Men's Volleyball",
                src:volleyball_M_W,
            },
            {
                id:6,
                head:"Women's Basket Ball",
                src:basketball_W_W,
            },
            {
                id:7,
                head:"Men'Kho Kho",
                src:khokho_M_W,
            },
            {
                id:8,
                head:"Women's Volleyball",
                src:volleyball_W_W,
            },
            {
                id:9,
                head:"Women's Kho Kho",
                src:khokho_W_W,
            },
        ],
        runners:[
            {
                id:1,
                head:"Men's Ball Badminton",
                src:ballbadminton_M_R,
            },
            {
                id:2,
                head:"Men's Fooball",
                src:football_M_R,
            },
            {
                id:3,
                head:"Men's Chess",
                src:chess_M_R,
            },
        ]
    }
const typhonz_list={
        winners:[
            {
                id:1,
                head:"Men's Ball Badminton",
                src:ballbadminton_M_W,
            },
            {
                id:2,
                head:"Men's Cricket",
                src:cricket_M_W,
            },
            {
                id:3,
                head:"Men's Basket Ball",
                src:basketball_M_W,
            },
            {
                id:4,
                head:"Men's Handball",
                src:Handball_M_W,
            },
            {
                id:5,
                head:"Men's Chess",
                src:chess_M_W,
            },
        ],
        runners:[
            {
                id:1,
                head:"Men's Badminton",
                src:badminton_M_R,
            },
            {
                id:2,
                head:"Men's Hockey",
                src:Hockey_M_R,
            },
            {
                id:3,
                head:"Men's Kho Kho",
                src:khokho_M_R,
            },
            {
                id:6,
                head:"Women's Volleyball",
                src:volleyball_W_R,
            },
            {
                id:4,
                head:"Women's Handball",
                src:Handball_W_R,
            },
            {
                id:5,
                head:"Men's Kabaddi",
                src:kabaddi_M_R,
            },
        ]
    }
const phoenix_list={
        winners:[
            {
                id:1,
                head:"Men's Fooball",
                src:football_M_W,
            },
            {
                id:2,
                head:"Men's Kabaddi",
                src:kabaddi_M_W,
            },
            {
                id:3,
                head:"Women's Handball",
                src:handball_W_W,
            },
        ],
        runners:[
            {
                id:5,
                head:"Women's Ball Badminton",
                src:ballbadminton_W_R,
            },
            {
                id:1,
                head:"Men's Basket Ball",
                src:basketball_M_R,
            },
            {
                id:2,
                head:"Women's Basket Ball",
                src:basketball_W_R,
            },
            {
                id:3,
                head:"Men's Volleyball",
                src:volleyball_M_R,
            },
            {
                id:4,
                head:"Men's Handball",
                src:handball_M_R,
            },
        ]
    }
const akkadians_list={
        winners:[
            // {
            //     id:1,
            //     head:"Men's Ball Badminton",
            //     src:ballbadminton_M_W,
            // },
            // {
            //     id:2,
            //     head:"Men's Cricket",
            //     src:cricket_M_W,
            // },
            // {
            //     id:3,
            //     head:"Men's Basket Ball",
            //     src:basketball_M_W,
            // },
        ],
        runners:[
            {
                id:3,
                head:"Women's Badminton",
                src:badminton_W_R,
            },
            {
                id:1,
                head:"Men's Cricket",
                src:cricket_M_R,
            },
            {
                id:2,
                head:"Women's Kho Kho",
                src:khokho_W_R,
            },
        ]
    }

const sport_cards=[
    {
        id:4,
        img: typhonz,
        head:"Typhonz",
        points:85+57,
        link:'/typhonz',
        wins:{
            winners:[
                "Men's Ball Badminton",
                "Men's Basketball",
                "Men's Cricket",
                "Men's Handball",
                "Men's Chess",
            ],
            runners:[
                "Men's Badminton",
                "Men's Hockey",
                "Women's Volley Ball",
                "Men's Kho-Kho",
                "Women's Handball",
                "Women's Chess",
                "Men's Kabaddi",
            ]
        }
    },
    {
        id:3,
        img: ignatz,
        head:"Ignatz",
        points:105+49,
        link:'/ignatz',
        wins:{
            winners:[
                "Women's Badminton",
                "Women's Ball Badminton",
                "Men's Badminton",
                "Men's Hockey",
                "Men's Volley Ball",
                "Women's Basket Ball",
                "Women's Volley Ball",
                "Men's Kho-Kho",
                "Women's Kho-Kho"
            ],
            runners:[
                "Men's Ball Badminton",
                "Men's Chess",
                "Men's Football",
            ]
        }
    },
    {
        id:2,
        img: rising,
        head:"Rising Phoenix",
        points:65+112,
        link:'/phoenix',
        wins:{
            winners:[
                "Women's Handball",
                "Women's Chess",
                "Men's Kabaddi",
                "Men's football",
            ],
            runners:[
                "Women's Ball Badminton",
                "Men's Basketball",
                "Men's Volley Ball",
                "Women's Basket Ball",
                "Men's Handball"
            ]
        }
    },
    {
        id:1,
        img: akkadians,
        head:"Akkadians",
        points:15+68,
        link:'/akkadians',
        wins:{
            // winners:[],
            runners:[
                "Women's Badminton",
                "Men's Cricket",
                "Women's Kho-Kho"
            ]
        }
    },
]
export const sport_achivement_info=[
    {
        'id':'1',
        'heading':'Winner',
        'sub-head':"Raveena, a third-year Civil student, competed in inter-university karate for Anna University and received her Form 3 certificate.",
        'pic':raveena,
        'content_1':"In the academic year 2014-2015, a notable event known as BAJA SAE took place under the auspices of the Society of Automotive Engineers (SAE). The event unfolded from February 12th to 15th, 2015, at the challenging terrain of Pithambur, located in the vicinity of the National Automotive Testing and R&D Infrastructure Project (NATRiP) in Indore.",
        'content_2':" BAJA SAE, renowned for its off-road vehicle design and fabrication challenges, drew the participation of 25 enthusiastic teams. This gathering of automotive enthusiasts and engineering minds showcased innovation, technical expertise, and a passion for pushing the boundaries of vehicular design and performance. The event not only provided a platform for friendly competition but also fostered knowledge exchange and collaboration within the automotive engineering community."
      
      },
      {
        'id':'2',
        'heading':'Winner ',
        'sub-head':"Vimanthan, a third-year ECE student, participated in the Southwest Inter-University Archery as part of Anna University's team and received his Form 3 certificate.",
        'pic':vimanthan,
        'content_1':"In the academic year 2014-2015, a notable event known as BAJA SAE took place under the auspices of the Society of Automotive Engineers (SAE). The event unfolded from February 12th to 15th, 2015, at the challenging terrain of Pithambur, located in the vicinity of the National Automotive Testing and R&D Infrastructure Project (NATRiP) in Indore.",
        'content_2':" BAJA SAE, renowned for its off-road vehicle design and fabrication challenges, drew the participation of 25 enthusiastic teams. This gathering of automotive enthusiasts and engineering minds showcased innovation, technical expertise, and a passion for pushing the boundaries of vehicular design and performance. The event not only provided a platform for friendly competition but also fostered knowledge exchange and collaboration within the automotive engineering community."
      
      },
      {
        'id':'3',
        'heading':'Winner ',
        'sub-head':"Subha of 3rd Year EEE secured 1st prize in the CM Trophy Tournament in chess.",
        'pic':subha,
        'content_1':"In the academic year 2014-2015, a notable event known as BAJA SAE took place under the auspices of the Society of Automotive Engineers (SAE). The event unfolded from February 12th to 15th, 2015, at the challenging terrain of Pithambur, located in the vicinity of the National Automotive Testing and R&D Infrastructure Project (NATRiP) in Indore.",
        'content_2':" BAJA SAE, renowned for its off-road vehicle design and fabrication challenges, drew the participation of 25 enthusiastic teams. This gathering of automotive enthusiasts and engineering minds showcased innovation, technical expertise, and a passion for pushing the boundaries of vehicular design and performance. The event not only provided a platform for friendly competition but also fostered knowledge exchange and collaboration within the automotive engineering community."
      
      },
      {
        'id':'4',
        'heading':'4th Place',
        'sub-head':"The women's basketball team secured 4th place in the CM Trophy Tournament 2024.",
        'pic':baseketball_CM,
        // 'content_1':"In the academic year 2014-2015, a notable event known as BAJA SAE took place under the auspices of the Society of Automotive Engineers (SAE). The event unfolded from February 12th to 15th, 2015, at the challenging terrain of Pithambur, located in the vicinity of the National Automotive Testing and R&D Infrastructure Project (NATRiP) in Indore.",
        // 'content_2':" BAJA SAE, renowned for its off-road vehicle design and fabrication challenges, drew the participation of 25 enthusiastic teams. This gathering of automotive enthusiasts and engineering minds showcased innovation, technical expertise, and a passion for pushing the boundaries of vehicular design and performance. The event not only provided a platform for friendly competition but also fostered knowledge exchange and collaboration within the automotive engineering community."
      
      },
      {
        'id':'5',
        'heading':'2nd Place',
        'sub-head': 'Harish, a final-year EEE student, secured 2nd position in the 50-meter butterfly event at the aquatic competitions and has been selected for the state-level CM Trophy Tournament.',        
        'pic':swimming,
        'content_1':"In the academic year 2014-2015, a notable event known as BAJA SAE took place under the auspices of the Society of Automotive Engineers (SAE). The event unfolded from February 12th to 15th, 2015, at the challenging terrain of Pithambur, located in the vicinity of the National Automotive Testing and R&D Infrastructure Project (NATRiP) in Indore.",
        'content_2':" BAJA SAE, renowned for its off-road vehicle design and fabrication challenges, drew the participation of 25 enthusiastic teams. This gathering of automotive enthusiasts and engineering minds showcased innovation, technical expertise, and a passion for pushing the boundaries of vehicular design and performance. The event not only provided a platform for friendly competition but also fostered knowledge exchange and collaboration within the automotive engineering community."
      
      },

      {
        'id':'6',
        'heading':'Runner',
        'sub-head': 'Ball badminton women secure second position in anna university zone 16 tournament',        
        'pic':badmittongirls,
        'content_1':"In the academic year 2014-2015, a notable event known as BAJA SAE took place under the auspices of the Society of Automotive Engineers (SAE). The event unfolded from February 12th to 15th, 2015, at the challenging terrain of Pithambur, located in the vicinity of the National Automotive Testing and R&D Infrastructure Project (NATRiP) in Indore.",
        'content_2':" BAJA SAE, renowned for its off-road vehicle design and fabrication challenges, drew the participation of 25 enthusiastic teams. This gathering of automotive enthusiasts and engineering minds showcased innovation, technical expertise, and a passion for pushing the boundaries of vehicular design and performance. The event not only provided a platform for friendly competition but also fostered knowledge exchange and collaboration within the automotive engineering community."
      
      },
      {
        'id':'6',
        'heading':'Runner',
        'sub-head': 'Chess men team secure runner up in Anna University zone 16 held at Mohamad sathak eng college kilakarai',        
        'pic':chess,
        'content_1':"In the academic year 2014-2015, a notable event known as BAJA SAE took place under the auspices of the Society of Automotive Engineers (SAE). The event unfolded from February 12th to 15th, 2015, at the challenging terrain of Pithambur, located in the vicinity of the National Automotive Testing and R&D Infrastructure Project (NATRiP) in Indore.",
        'content_2':" BAJA SAE, renowned for its off-road vehicle design and fabrication challenges, drew the participation of 25 enthusiastic teams. This gathering of automotive enthusiasts and engineering minds showcased innovation, technical expertise, and a passion for pushing the boundaries of vehicular design and performance. The event not only provided a platform for friendly competition but also fostered knowledge exchange and collaboration within the automotive engineering community."
      
      },
      {
        'id':'7',
        'heading':'3rd place',
        'sub-head': 'Hockey men secure third in anna university zone 16 tournament',        
        'pic':hockeyboys,
        'content_1':"In the academic year 2014-2015, a notable event known as BAJA SAE took place under the auspices of the Society of Automotive Engineers (SAE). The event unfolded from February 12th to 15th, 2015, at the challenging terrain of Pithambur, located in the vicinity of the National Automotive Testing and R&D Infrastructure Project (NATRiP) in Indore.",
        'content_2':" BAJA SAE, renowned for its off-road vehicle design and fabrication challenges, drew the participation of 25 enthusiastic teams. This gathering of automotive enthusiasts and engineering minds showcased innovation, technical expertise, and a passion for pushing the boundaries of vehicular design and performance. The event not only provided a platform for friendly competition but also fostered knowledge exchange and collaboration within the automotive engineering community."
      
      },


]
const sports_menu=[
    {
        id: "1",
        path: "/sportsachievement",
        link: "Achievement",
        arl: "l",
    }
]

export {sport_cards,sports_slideshow,sportstime,sportslist,sportsvision,sportsmission,sports_meet_2k24_schedule,ignatz_list,typhonz_list,phoenix_list,akkadians_list,sports_menu}
