// import dp from "../assets/pictures/dp.jpg";
// import dpm from "../assets/pictures/boy.webp";
// import dpf from "../assets/pictures/girl.webp";
// import cover from "../assets/pictures/microchip.webp";

// import swayam from "../assets/pictures/swayam.webp";
// import robotics from "../assets/pictures/robotics.webp";
// import placement from "../assets/pictures/placement.webp";

// import ece_adc_lab from "../assets/pictures/ece_dept/ece_labs/ece_adc_lab-c.webp"
// import ece_rfs_lab from "../assets/pictures/ece_dept/ece_labs/ece_rfs_lab-c.webp"
// import ece_fo_lab from "../assets/pictures/ece_dept/ece_labs/ece_fo_lab.webp"
// import ece_cn_lab from "../assets/pictures/ece_dept/ece_labs/ece_cn_lab.webp"
// import ece_vlsi_lab from "../assets/pictures/ece_dept/ece_labs/ece_vlsi_lab.webp"
// import ece_ic_lab from "../assets/pictures/ece_dept/ece_labs/ece_ic_lab.webp"
// import ece_sig_lab from "../assets/pictures/ece_dept/ece_labs/ece_sig_lab.webp"
// import ece_edc_lab from "../assets/pictures/ece_dept/ece_labs/ece_edc_lab.webp"
// import ece_rfm_lab from "../assets/pictures/ece_dept/ece_labs/ece_rfm_lab.webp"
// import ece_opt_lab from "../assets/pictures/ece_dept/ece_labs/ece_opt_lab.webp"
// import ece_pcb_lab from "../assets/pictures/ece_dept/ece_labs/PCB_Fabrication_Station.webp"
// import ece_antenna_lab from "../assets/pictures/ece_dept/ece_labs/ECE_Ant_Chamber_1.webp";

// import thiran_2k22 from "../assets/pictures/ece_dept/stud_activities/Thiran 2k22.webp";
// import circuit_debug from "../assets/pictures/ece_dept/stud_activities/Circuit Debugging.webp";
// import best_manager from "../assets/pictures/ece_dept/stud_activities/best_manager.webp";
// import tech_quiz from "../assets/pictures/ece_dept/stud_activities/tech_quiz.webp";
// import paper_presentation from "../assets/pictures/ece_dept/stud_activities/paper_presentation.webp";
// import circuit_designers from "../assets/pictures/ece_dept/stud_activities/circuit_designers.webp";
// import top_design1 from "../assets/pictures/ece_dept/stud_activities/top_design1.webp";
// import top_design2 from "../assets/pictures/ece_dept/stud_activities/top_designer2.webp";
// import hrk_top_design_2k22 from "../assets/pictures/ece_dept/stud_activities/hrk_topdesign_2k22.webp";
// import sow_top_design_2k22 from "../assets/pictures/ece_dept/stud_activities/sow_topdesign_2k22.webp";
// import anu_top_design_2k22 from "../assets/pictures/ece_dept/stud_activities/anu_topdesign_2k22.webp";
// import asw_top_design_2k22 from "../assets/pictures/ece_dept/stud_activities/asw_topdesign_2k22.webp";
// import malai_thiran_23 from "../assets/pictures/ece_dept/stud_activities/malai_thiran_23.webp";
// import mm_thiran_23 from "../assets/pictures/ece_dept/stud_activities/mm_thiran_23.webp";
// import gok_thiran_23 from "../assets/pictures/ece_dept/stud_activities/gok_thiran_23.webp";
// import jos_thiran_23 from "../assets/pictures/ece_dept/stud_activities/jos_thiran_23.webp";

 

// import ncmoc19 from '../assets/pictures/ece_dept/ece_events/ncmoc19.webp'
// import stal21 from '../assets/pictures/ece_dept/ece_events/stalwartz21.webp'
// import prag21 from '../assets/pictures/ece_dept/ece_events/pragyanz21.webp'
// import prag22 from '../assets/pictures/ece_dept/ece_events/pragyanz22.webp'
// import unknown from '../assets/pictures/ece_dept/ece_events/unkown.webp'

// import somasundaram from "../assets/pictures/ece_dept/ece_faculties/Somasundaram.webp";

// import asr from "../assets/pictures/ece_dept/ece_faculties/ASR.webp";
// import aruna from "../assets/pictures/ece_dept/ece_faculties/Ece_Aruna Rani M-1.webp"
// import helena from "../assets/pictures/ece_dept/ece_faculties/Ece_HelenaD-1.webp"
// import rajesh from "../assets/pictures/ece_dept/ece_faculties/Ece_KarpagarajeshG-1.webp"
// import keerthana from "../assets/pictures/ece_dept/ece_faculties/Ece_KeerthanaP-1.webp"
// import mahendran from "../assets/pictures/ece_dept/ece_faculties/Ece_Mahendran C-1.webp"
// import merlin from "../assets/pictures/ece_dept/ece_faculties/Ece_Merlin sheeba X-1.webp"
// import khanna from "../assets/pictures/ece_dept/ece_faculties/Ece_Rajesh kanna B-1.webp"
// import ramya from "../assets/pictures/ece_dept/ece_faculties/Ece_Ramya SP-1.webp"
// import renisha from "../assets/pictures/ece_dept/ece_faculties/Ece_RenishaG-1.webp"
// import sindhu from "../assets/pictures/ece_dept/ece_faculties/Ece_SindujaC-1.webp"
// import sezhina from "../assets/pictures/ece_dept/ece_faculties/sezhina.webp"
// import karthika from "../assets/pictures/ece_dept/ece_faculties/karthika.webp"
// import nandhini from "../assets/pictures/ece_dept/ece_faculties/dhevanandhini.webp"

// import akila from "../assets/pictures/ece_dept/ece_faculties/akila.webp"
// import chandrasekar from "../assets/pictures/ece_dept/ece_faculties/chandrasekaran.webp"
// import selvi from "../assets/pictures/ece_dept/ece_faculties/tamilselvi.webp"
// import vasanthi from "../assets/pictures/ece_dept/ece_faculties/vasanthi.webp"
// import karthi from "../assets/pictures/ece_dept/ece_faculties/karthikeyan.webp"
// import sankar from "../assets/pictures/ece_dept/ece_faculties/sankar.webp"

// import priya_21 from '../assets/pictures/ece_dept/ece_rankers/priya_21.webp'
// import navin_21 from '../assets/pictures/ece_dept/ece_rankers/navin_21.webp'
// import aish_20 from '../assets/pictures/ece_dept/ece_rankers/aishwarya_20.webp'
// import sujitha_20 from '../assets/pictures/ece_dept/ece_rankers/sujitha_20.webp'


import alagappan from "../assets/pictures/ece_alumni_pics/Alagappan Krishnan.jpg"
import amuthan from "../assets/pictures/ece_alumni_pics/Amudhan Arumugam.jpg"
import raja from "../assets/pictures/ece_alumni_pics/Dr B Raja Dhandapani.jpg"
import periyaswamy from "../assets/pictures/ece_alumni_pics/Dr Periysamy Mariappan.jpg"
import jayachitra from "../assets/pictures/ece_alumni_pics/Dr. R K Jeyachitra.jpg"
import jayalakshmi from "../assets/pictures/ece_alumni_pics/Jayalakshmi.jpg"
import Senthilkumar from "../assets/pictures/ece_alumni_pics/K Senthilkumar.jpg"
import manivanan from "../assets/pictures/ece_alumni_pics/Manivannan.jpg"
import premmurugasen from "../assets/pictures/ece_alumni_pics/Prem Murugesan.jpg"
import rameshsudaram from "../assets/pictures/ece_alumni_pics/Ramesh Sundaram.jpg"
import purijegannathan from "../assets/pictures/ece_alumni_pics/Puri Jegannathan.jpg"

import satishthiyagarajan from "../assets/pictures/ece_alumni_pics/Satish Thiyagarajan.jpg"

import tgvenkatesh from "../assets/pictures/ece_alumni_pics/T G Venkatesh.jpg"
import ramsankar from "../assets/pictures/ece_alumni_pics/B Ramsankar.jpg"
import Balasubramanian from "../assets/pictures/ece_alumni_pics/Balasubramanian.jpg"
import baskaranmuthuswamy from "../assets/pictures/ece_alumni_pics/Baskaran Muthusamy.jpg"
import sridhevi from "../assets/pictures/ece_alumni_pics/Dr B Sridevi.jpg"
import Mohamedsiddique from "../assets/pictures/ece_alumni_pics/Mohamed Siddique.jpg"
import mohankumar from "../assets/pictures/ece_alumni_pics/Mohankumar Jagannathan.jpg"
import dineshbabu from "../assets/pictures/ece_alumni_pics/R. Dinesh Babu.jpg"
import rajkumar from "../assets/pictures/ece_alumni_pics/Rajkumar R.jpg"
import sivakumarnm from "../assets/pictures/ece_alumni_pics/Sivakumar Nm.jpg"
import srinivasan from "../assets/pictures/ece_alumni_pics/Srinivasan Ranganathan.jpg"
import sujaviswesan from "../assets/pictures/ece_alumni_pics/Suja Viswesan.jpg"
import dakshinamurthy from "../assets/pictures/ece_alumni_pics/A Dakshinamurthy.jpg"
import berlinsharmila from "../assets/pictures/ece_alumni_pics/Berlin Sharmila Manickam.jpg"
import chidambaram from "../assets/pictures/ece_alumni_pics/Chidambaram M.jpg"
import dittakavi from "../assets/pictures/ece_alumni_pics/DittakaviSuryanarayanan.jpg"
import komalavalli from "../assets/pictures/ece_alumni_pics/Dr C Komalavalli.jpg"
import dhinakaran from "../assets/pictures/ece_alumni_pics/Dr R DhinakaranSamuel.jpg"
import selvakumarraja from "../assets/pictures/ece_alumni_pics/Dr S Selvakumar Raja.jpg"
import durga from "../assets/pictures/ece_alumni_pics/Durga Priyadharshini.jpg"
import eswaran from "../assets/pictures/ece_alumni_pics/Eswaran KP.jpg"
import ganesh from "../assets/pictures/ece_alumni_pics/Ganesh CS.jpg"
import gokul from "../assets/pictures/ece_alumni_pics/Gokul R.jpg"
import gokulrama from "../assets/pictures/ece_alumni_pics/Gokul Ramamoorthy.jpg"
import govindamoorthi from "../assets/pictures/ece_alumni_pics/Govindamoorthi P.jpg"
import bharathiradha from "../assets/pictures/ece_alumni_pics/J Bharathi Radha.jpg"
import muthu from "../assets/pictures/ece_alumni_pics/K Muthu.jpg"
import kana from "../assets/pictures/ece_alumni_pics/Kanna K.jpg"
import karthik from "../assets/pictures/ece_alumni_pics/Karthik K.jpg"
import kumaraguru from "../assets/pictures/ece_alumni_pics/Kumaraguru.jpg"
import mathuravani from "../assets/pictures/ece_alumni_pics/Madhuravani.jpg"
import mahadevi from "../assets/pictures/ece_alumni_pics/Mahadevi T.jpg"
import manikandan from "../assets/pictures/ece_alumni_pics/Manikandan Raghavan.jpg"
import meenambal from "../assets/pictures/ece_alumni_pics/Meenambal M.jpg"
import nagendran from "../assets/pictures/ece_alumni_pics/Nagendran D.jpg"
import narayana from "../assets/pictures/ece_alumni_pics/Narayana Manickavasagam.jpg"
import naveen from "../assets/pictures/ece_alumni_pics/Naveen Sam T.jpg"
import navin from "../assets/pictures/ece_alumni_pics/Navin Frank l.jpg"
import pitchai from "../assets/pictures/ece_alumni_pics/Pitchai Raman M.jpg"
import ponnumani from "../assets/pictures/ece_alumni_pics/Ponnumani M.jpg"
import priyaDharshini from "../assets/pictures/ece_alumni_pics/Priyadharshini Jeganathan.jpg"
import balashanmuga from "../assets/pictures/ece_alumni_pics/R.P. Bala shanmuga Sundaram.jpg"
import rajagopalan from "../assets/pictures/ece_alumni_pics/Rajagopalan Ravi.jpg"
import ramaiah from "../assets/pictures/ece_alumni_pics/Ramaiah S.jpg"
import ramesh from "../assets/pictures/ece_alumni_pics/Ramesh M.jpg"
import ramkumarB from "../assets/pictures/ece_alumni_pics/Ramkumar B.jpg"
import ramkumarR from "../assets/pictures/ece_alumni_pics/Ramkumar R.jpg"
import Ponnalagu from "../assets/pictures/ece_alumni_pics/RN Ponnalagu.jpg"
import roja from "../assets/pictures/ece_alumni_pics/Roja K S.jpg"
import manohar from "../assets/pictures/ece_alumni_pics/S. Manohar.jpg"
import saravanaMohan from "../assets/pictures/ece_alumni_pics/Saravanan Mohan.jpg"
import senthilanathanpra from "../assets/pictures/ece_alumni_pics/Senthil Anandha Prakash J.jpg"
import sheik from "../assets/pictures/ece_alumni_pics/Shiek Ashik S.jpg"
import sivashankar from "../assets/pictures/ece_alumni_pics/Siva Shankar.jpg"
import sivanantharaja from "../assets/pictures/ece_alumni_pics/Sivanantha Raja. A.jpg"
import sowbharnika from "../assets/pictures/ece_alumni_pics/Sowbharanika Kumar K.jpg"
import srikanth from "../assets/pictures/ece_alumni_pics/Srikanth Ramachandran.jpg"
import srinivasanallaiya from "../assets/pictures/ece_alumni_pics/Srinivasa Nallaiya.jpg"
import srinivasanranganathan from "../assets/pictures/ece_alumni_pics/Srinivasan Ranganathan.jpg"
import subbiahrm from "../assets/pictures/ece_alumni_pics/Subbiah R M.jpg"
import subbhuraj from "../assets/pictures/ece_alumni_pics/Subburaj Ramasamy.jpg"
import sudhakar from "../assets/pictures/ece_alumni_pics/Sudhakar VR.jpg"
import tamilmanan from "../assets/pictures/ece_alumni_pics/Tamilmanam Sambasivam.jpg"
import thackor from "../assets/pictures/ece_alumni_pics/Thackor Singh.jpg"
import thirumaraiselvan from "../assets/pictures/ece_alumni_pics/Thirumaraiselvan.jpg"
import velupalani from "../assets/pictures/ece_alumni_pics/Velu Palani.jpg"
import venkataraghavan from "../assets/pictures/ece_alumni_pics/Venkataraghavan PK.jpg"
import vijaypriya from "../assets/pictures/ece_alumni_pics/Vijayapriya V.jpg"
import partha from "../assets/pictures/ece_alumni_pics/Parthasarathi.jpg"
import surya from "../assets/pictures/ece_alumni_pics/Surya UR.jpg"

const dpm="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/boy.webp"
const dpf="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/girl.webp"
const cover="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/microchip.webp"


const swayam="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/swayam.webp"
const robotics="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/robotics.webp"
const placement="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/placement.webp"

const sundar_23="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/eliterankers/sundarapandi_23.webp"
const bharathi_23="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/eliterankers/bharathi_23.webp"
const dhanaraman_22="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/eliterankers/dhanaraman_22.webp"
const swetha_22="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/eliterankers/swethasri_22.webp"
const priya_21  ="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/eliterankers/priya_21.webp"
const navin_21 ="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/eliterankers/navin_21.webp"
const aish_20 ="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/eliterankers/aishwarya_20.webp"
const sujitha_20="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/eliterankers/sujitha_20.webp"
const somasundaram = "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/faculties/teachingfac/Somasundaram.webp"
const asr= "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/faculties/teachingfac/ASR.webp"
const aruna = "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/faculties/teachingfac/Ece_Aruna+Rani+M-1.webp"
const helena = "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/faculties/teachingfac/Ece_HelenaD-1.webp"
// const  rajesh= "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/faculties/teachingfac/Ece_Rajesh+kanna+B-1.webp"
const keerthana = "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/faculties/teachingfac/Ece_KeerthanaP-1.webp"
const mahendran = "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/faculties/teachingfac/Ece_Mahendran+C-1.webp"
const merlin = "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/faculties/teachingfac/Ece_Merlin+sheeba+X-1.webp"
const khanna = "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/faculties/teachingfac/Ece_Rajesh+kanna+B-1.webp"
const ramya = "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/faculties/teachingfac/Ece_Ramya+SP-1.webp"
const  renisha= "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/faculties/teachingfac/Ece_RenishaG-1.webp"
const sindhu = "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/faculties/teachingfac/Ece_Sindhuja.webp"
const  sezhina= "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/faculties/teachingfac/sezhina.webp"
const karthika = "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/faculties/teachingfac/karthika.webp"
const  nandhini= "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/faculties/teachingfac/dhevanandhini.webp"
const akila="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/faculties/supportingfac/akila.webp"
const chandrasekar="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/faculties/supportingfac/chandrasekaran.webp"
const selvi="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/faculties/supportingfac/tamilselvi.webp"
const vasanthi="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/faculties/supportingfac/vasanthi.webp"
const karthi="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/faculties/supportingfac/karthikeyan.webp"
const sankar="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/faculties/supportingfac/sankar.webp"
const karpagagrajesh = "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/science_humanities/Physics/Phy_faculties/Ece_KarpagarajeshG.webp"
const ece_adc_lab="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/laboratories/academiclabs/ece_adc_lab-c.webp"
const ece_rfs_lab="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/laboratories/academiclabs/ece_rfs_lab-c.webp"
const ece_fo_lab="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/laboratories/academiclabs/ece_fo_lab.webp"
const ece_opt_lab="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/laboratories/academiclabs/ece_opt_lab.webp"
const ece_vlsi_lab="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/laboratories/academiclabs/ece_vlsi_lab.webp"
const ece_sig_lab="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/laboratories/academiclabs/ece_sig_lab.webp"
const ece_edc_lab="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/laboratories/academiclabs/ece_edc_lab.webp"
const ece_cn_lab="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/laboratories/academiclabs/ece_cn_lab.webp"
const ece_rfm_lab="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/laboratories/academiclabs/ece_rfm_lab.webp"

const ece_ic_lab="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/laboratories/industryandinstitutelab/ece_ic_lab.webp"
const ece_pcb_lab="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/laboratories/industryandinstitutelab/PCB_Fabrication_Station.webp"
const ece_antenna_lab="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/laboratories/industryandinstitutelab/ECE_Ant_Chamber_1.webp"

const rp2040 = "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/events/rp2040_training.webp"
const genomics = "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/events/genomics.webp"
const festronix_23 = "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/events/festronix'23+(2).webp"
const ncmoc19 ="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/events/ncmoc19.webp"
const stal21="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/events/stalwartz21.webp"
const prag21="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/events/pragyanz21.webp"
const prag22="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/events/pragyanz22.webp"
const unknown="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/events/unkown.webp"

const thiran_2k22="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/studentactivity/Thiran+2k22.webp"
const circuit_debug="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/studentactivity/Circuit+Debugging.webp"
const best_manager="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/studentactivity/best_manager.webp"
const tech_quiz="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/studentactivity/tech_quiz.webp"
const paper_presentation="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/studentactivity/paper_presentation.webp"
const circuit_designers="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/studentactivity/circuit_designers.webp"
const top_design1="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/studentactivity/top_design1.webp"
const top_design2="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/studentactivity/top_designer2.webp"
const hrk_top_design_2k22="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/studentactivity/hrk_topdesign_2k22.webp"
const sow_top_design_2k22="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/studentactivity/sow_topdesign_2k22.webp"
const anu_top_design_2k22="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/studentactivity/anu_topdesign_2k22.webp"
const asw_top_design_2k22="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/studentactivity/asw_topdesign_2k22.webp"
const malai_thiran_23="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/studentactivity/malai_thiran_23.webp"
const gok_thiran_23="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/studentactivity/gok_thiran_23.webp"
const jos_thiran_23="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/studentactivity/jos_thiran_23.webp"
const mm_thiran_23="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/studentactivity/mm_thiran_23.webp"
const vimanthan="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/studentactivity/vimanthan_archery.webp"
const sivakumar="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/studentactivity/siva_cup.webp"
const arunkumar="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/studentactivity/adshoot_arun.webp"
const top_design1_24="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/studentactivity/top_des24_1st.webp"
const top_design2_24="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/studentactivity/top_des24_2nd.webp"
const basketball="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/studentactivity/ece24_basketball.webp"
const football="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/studentactivity/ece_24football.webp"
const handball="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/studentactivity/handball24_ece.webp"

const ece_dept_menu = [
  {
    id: "1",
    path: "/ecedept",
    link: "About the Department",
    arl: "l",
  },
  {
    id: "2",
    path: "/ecedept/ece-peo",
    link: "Programme Objectives & Outcomes",
    arl: "l",
  },
  {
    id: "3",
    path: "/ecedept/ece-faculties",
    link: "Faculty",
    arl: "l",
  },
  {
    id: "4",
    path: "/ecedept/ece-lab",
    link: "Laboratory Facilities",
    arl: "l",
  },
  {
    id: "5",
    path: "/ecedept/ece-dept-lib",
    link: "Department Library",
    arl: "l",
  },
  {
    id: "6",
    path: "/ecedept/ece-research",
    link: "Research and Publications",
    arl: "l",
  },
  {
    id: "7",
    path: "/ecedept/ece-dept-events",
    link: "Association Events",
    arl: "l",
  },
  {
    id: "8",
    path: "/ecedept/ece-placement",
    link: "Placement Activites",
    arl: "l",
  },
 
  {
    id: "9",
    path: "/ecedept/ece-rankers",
    link: "Elite Rankers",
    arl: "l",
  },
  {
    id: "10",
    path: "/ecedept/ece-student-activity",
    link: "Student Activities",
    arl: "l",
  },
  // {
  //   id: "11",
  //   path: "/ecedept/ece-projects",
  //   link: "Best Projects",
  //   arl: "l",
  // },
  {
    id: "12",
    path: "/ecedept/ece-antenna-testing",
    link: "Antenna Testing",
    arl: "l",
  },
  {
    id: "13",
    path: "/ecedept/Ece_elect_test_coe",
    link: "Electronics Test Engineering",
    arl: "l",
  },
  {
    id: "14",
    path: "/ecedept/ece-alumni",
    link: "Alumni",
    arl: "l",
  },
];

const ece_hod = {
  name: "Dr.A.Sivanantha Raja",
  designation: "Head of the Department, ECE",
  pic: asr,
  content:
  "Welcome to the Department of Electronics and Communication Engineering! "+
"Our department is committed to providing our students with a high-quality education that will prepare them for successful careers in the field of Electronics and Communication Engineering. We offer a comprehensive curriculum that covers the fundamental concepts and latest trends in the field, as well as opportunities for hands-on learning through our state-of-the-art laboratories."+
" Our faculty are well experienced and dedicated educators who are passionate about teaching and mentoring students. They are also actively engaged in research, which allows them to stay up-to-date on the latest developments in the field and share their knowledge with our students."+
"In addition to our strong academic program, we also offer a variety of extracurricular activities that allow our students to get involved and build their skills. These activities include student clubs, design competitions, and research projects."+
" We believe that our department provides our students with the best possible preparation for a successful career in Electronics and Communication Engineering. We are confident that the students will thrive in our program and go on to make significant contributions to the field."
};

const ece_fac_profiles = [
  {
    id: "1",
    cover: cover,
    dp: asr,
    name: "Dr.A.Sivanantha Raja",
    Designation: "Head of the Department",
    mailid: "sivanantharaja@accet.ac.in",
    pdf: "https://drive.google.com/file/d/1R9A7ul9goH87qnMYhIZQvI8qFCNcR2iX/view?usp=drive_link",
  },
  {
    id: "2",
    cover: cover,
    dp: karpagagrajesh,
    name: "Dr.G.Karpagarajesh",
    Designation: "Associate Professor",
    mailid: "gkrajesh1@gmail.com",
    pdf: "https://drive.google.com/file/d/1NJ0Um7-gKLpc5v-WkQLJ40yrlZnNeXgV/view?usp=drive_link",
  },
  {
    id: "3",
    cover: cover,
    dp: renisha,
    name: "Dr.Renisha G",
    Designation: "Associate Professor",
    mailid: "renisha.ece@gmail.com",
    pdf: "https://drive.google.com/file/d/12KYashHei2R52bEaM0KDNBhoMiKU_k66/view?usp=drive_link",
  },
  {
    id: "4",
    cover: cover,
    dp: helena,
    name: "Dr.D.Helena Margaret",
    Designation: "Associate Professor",
    mailid: "helenaraj@yahoo.com",
    pdf: "https://drive.google.com/file/d/1YnfHK-jwVQ_U8XSfZMwNrubMH7S8fgQs/view?usp=drive_link",
  },
  {
    id: "5",
    cover: cover,
    dp: mahendran,
    name: "Dr.C.Mahendran",
    Designation: "Assistant Professor",
    mailid: "mahendran@accet.ac.in",
    pdf: "https://drive.google.com/file/d/1x8kAOCym5yWHJEvmAbvNV2kCuwVKgboy/view?usp=drive_link",
  },
  {
    id: "6",
    cover: cover,
    dp: somasundaram,
    name: "Dr.S.Somasundaram",
    Designation: "Associate Professor",
    mailid: "ssm98@rediffmail.com",
    pdf: "https://drive.google.com/file/d/163gMBrbM22WAadjTr2AFz4cHJBFMJf7_/view?usp=drive_link",
  },
  {
    id: "7",
    cover: cover,
    dp: karthika,
    name: "Dr.C.Karthika Pragadeeswari",
    Designation: "Assistant Professor",
    mailid: "bk.karthika1969@gmail.com",
    pdf: "https://drive.google.com/file/d/1u1pS75PDwZ4xLl7yhVyJFx4U_Iryzm5z/view?usp=drive_link",
  },
  {
    id: "8",
    cover: cover,
    dp: nandhini,
    name: "Dr.G.Dhevanandhini",
    Designation: "Assistant Professor",
    mailid: "dhevakeshav@gmail.com",
    pdf: "https://drive.google.com/file/d/1EAyFUlaybqf58HDIQIR1wU7_GSKIDtaD/view?usp=drive_link",
  },
  {
    id: "9",
    cover: cover,
    dp: khanna,
    name: "Dr.B.Rajesh Kanna",
    Designation: "Assistant Professor",
    mailid: "rajeshkanna.ece.au@gmail.com",
    pdf: "https://drive.google.com/file/d/1aQxGj7vgE1McsS4UULIW2PPYPuk7K2VQ/view?usp=drive_link",
  },
  {
    id: "10",
    cover: cover,
    dp: sezhina,
    name: "P.Sezhina Kanakambika",
    Designation: "Assistant Professor",
    mailid: "sezhina@gmail.com",
    pdf: "https://drive.google.com/file/d/16zoEOL7QgiNCEoQ3SaSxov7y2f34XKTj/view?usp=drive_link",
  },
  {
    id: "11",
    cover: cover,
    dp: sindhu,
    name: "C.Sinduja",
    Designation: "Assistant Professor",
    mailid: "sindhu.mzcet@gmail.com",
    pdf: "https://drive.google.com/file/d/1XSyQR1gJwej0E7xagF4u9oydmF0D6h8P/view?usp=drive_link",
  },
  {
    id: "12",
    cover: cover,
    dp: aruna,
    name: "M.Aruna Rani",
    Designation: "Assistant Professor(adhoc)",
    mailid: "arunaselva27@gmail.com",
    pdf: "https://drive.google.com/file/d/1iZp_6Wj4nb6q9TxaK6yxXn_yHW-J6jwc/view?usp=drive_link",
  },
  {
    id: "13",
    cover: cover,
    dp: ramya,
    name: "SP.Ramya",
    Designation: "Assistant Professor",
    mailid: "sp.ramya08@gmail.com",
    pdf: "https://drive.google.com/file/d/1L1R7FViSxmTaNyeJP9Y7bKDWWMO79gOU/view?usp=drive_link",
  },
  // {
  //   id: "14",
  //   cover: cover,
  //   dp: keerthana,
  //   name: "Keerthana.P",
  //   Designation: "Assistant Professor",
  //   mailid: "keerthiperumal@gmail.com",
  //   pdf: "https://drive.google.com/file/d/1JGWDBNKTk4ZAovxUYokqidpefKClCgl5/view?usp=drive_link",
  // },
  {
    id: "15",
    cover: cover,
    dp: merlin,
    name: "Mrs.X.Merlin Sheeba",
    Designation: "Assistant Professor",
    mailid: "renachristina@gmail.com",
    pdf: "https://drive.google.com/file/d/1X00Urq2JZwhC8QP6QCchELJxl4F5Pr4e/view?usp=drive_link",
  },
];

const ece_sup_profiles = [
  {
    id: "1",
    cover: cover,
    dp: chandrasekar,
    name: "Mr.B.Chandrasekaran",
    Designation: "Electrician",
    mailid: "Chandrasekaran70@gmail.com",
    pdf: "",
  },
  {
    id: "2",
    cover: cover,
    dp: vasanthi,
    name: "Ms.N.Vasanthi Meena",
    Designation: "Lab Assistant",
    mailid: "vasanthimeena8686@gmail.com",
    pdf: "",
  },
  {
    id: "3",
    cover: cover,
    dp: selvi,
    name: "Ms.D.Tamil Selvi",
    Designation: "Mech.Grade-II",
    mailid: "tamilmd123@gmail.com",
    pdf: "",
  },
  {
    id: "4",
    cover: cover,
    dp: karthi,
    name: "Mr.G.Karthikeyan",
    Designation: "Mech.Grade-II",
    mailid: "gkarthikece1973@gmail.com",
    pdf: "",
  },
  {
    id: "5",
    cover: cover,
    dp: sankar,
    name: "Mr.K.Sankar",
    Designation: "Cleaner",
    mailid: "",
    pdf: "",
  },
  {
    id: "6",
    cover: cover,
    dp: akila,
    name: "Ms.A.Akila",
    Designation: "Lab Assistant",
    mailid: "Akilaanu1999@gmail.com",
    pdf: "",
  },
];

const ece_research_info=[
  {
    'name':"Dr. A.Sivanantha Raja",
    'area':"Optical Communication"
  },
  {
    'name':"Dr. G.Karpagarajesh",
    'area':"Optical communication and networks"
  },
  {
    'name':"Dr. G Renisha",
    'area':"Signal processing"
  },
  {
    'name':"Dr. D.Helena Margaret",
    'area':"Microwave Antenna"
  },
  {
    'name':"Prof. C.Mahendran",
    'area':"Microwave Antenna"
  },
  {
    'name':"Dr. S.Somasundaram",
    'area':"Polynomial Controllers, Stable Controllers"
  },
  {
    'name':"Dr. C.Karthika Pragadeeswari",
    'area':"Video Surveilance"
  },
  {
    'name':"Dr. G.Dhevanandhini",
    'area':"Digital Image Processing"
  },
  {
    'name':"Dr. B.Rajeshkanna",
    'area':"Wireless Sensor Networks"
  },
];

const patent_info=[
  {
    'name':"Dr. A.Sivanantha Raja",
    'title':"Noise free Public Address System for Auditorium using White Light Emitting Diode Communication",
    'App':"202041002376",
    'status':"Applied"
  },
];

const guide_info=[
  {
    'sno':"1",
    'name':"Dr. A.Sivanantha Raja",
    'npprod':"12",
    'nppur':"04",
    'msres':"02"
  },
  {
    'sno':"2",
    'name':"Dr. G.Karpagarajesh",
    'npprod':"-",
    'nppur':"04",
    'msres':"-"
  },
  {
    'sno':"3",
    'name':"Dr. S.Somasundaram",
    'npprod':"04",
    'nppur':"-",
    'msres':"-"
  },
  {
    'sno':"4",
    'name':"Dr. B.Rajeshkanna",
    'npprod':"-",
    'nppur':"02",
    'msres':"-"
  },
];

const scholar_info1=[
  {
    'sno':"1",
    'name':"A.Anand",
    'area':"Investigation on Brillouin Scattering Effect in Fiber Optic Communication",
  },
  {
    'sno':"2",
    'name':"N.Gopi",
    'area':"Investigation on Nonlinear Polarization Effects in Fiber Optic Communication",
  },
  {
    'sno':"3",
    'name':"Yarra Naga Subrahmanya Vamsi Mohan",
    'area':"Outdoor Applications of Optical Wireless Communication Systems",
  },
  {
    'sno':"4",
    'name':"M. Sindhu",
    'area':"Application of SOA for optical networks",
  },
];

const scholar_info2=[
  {
    'sno':"1",
    'name':" K John Samuel Raj",
    'area':"Identification of Malicious user and its spectral performance Analysis using Improvised Supervised Learning Algorithm in Cognitive Radio Networks",
  },
  {
    'sno':"2",
    'name':"S Amutha",
    'area':"An optimized multichannel slotted detection multiple access protocol  for Wireless Sensor Network (WSN) Applications",
  },
  {
    'sno':"3",
    'name':"Bala Karthick",
    'area':"PCOD detection using transfer learning",
  },
  {
    'sno':"4",
    'name':"S Sarathi",
    'area':"VLSI deisgn - an efficient power consumption built in self test architecture using test pattern technique",
  },
];

const scholar_info3=[
  {
    'sno':"1",
    'name':"T. Geetha",
    'area':"Design and real time implementation of Stable PI Controller for Spherical tank process"
  },
];

const scholar_info4=[
  {
    'sno':"1",
    'name':"Mr.Kanaka Raju Penmetsa",
    'area':"Diverse image segmentation methods for identifying and estimating the severity of plaque psoriasis in human skin"
  },
  {
    'sno':"2",
    'name':"Kataru Shravan Kumar",
    'area':"Performance analysis for wireless sensor networks in IOT enabled Green Power Applications"
  },
];

const ece_siva=[
  {
    'id':"1",
    'point':"Shanmuga Sundar,A.Sivanantha Raja, C.Sanjeeviraja and D.Jeyakumar (2015), “Syntheis and characterization of novel siloxane based transparent and flexible substrate for OLEDs”, Nanoelectronics and Sensors, ISBN: 978-93-85436-94-9, Bloomsbury Publication."
  },
  {
    'id':"2",
    'point':"Shanmuga Sundar,V.Nidhya Vijay, T.Sridarshini and A.Sivanantha Raja (2018), “Performance analysis of multichannel EDM hybrid Optical communication system for long haul communications”, Frontier Research and Innovation in Optoelectronics Technology and Industry, ISBN: 978-1-138-33178-5, CRC Press, Taylor & Francis."
  },
  {
    'id':"3",
    'point':"Shanmuga Sundar, A.Sivanantha Raja, R.Saravanan and Marcos Flores Carrasco (2018), “Photocatalyst for indoor air pollution: a brief review” Green Photocatalysts for Energy and Environmental Process, Springer, doi: 10.1007/978-3-030-17638-9, ISBN: 978-3-030-17638-9."
  },
  {
    'id':"4",
    'point':"Shanmuga Sundar, T.Sridarshini, R.Sitharthan, Madurakavi Karthikeyan, A.Sivanantha Rajaand Marcos Flores Carrasco (2019), “Performance investigation of 16/32- channel DWDM PON and long-reach PON systems using an ASE noise source”, Advances in Optoelectronic Technology and Industry Development – Jose & Ferreira (eds.), ISBN: 978-0-367-24634-1, CRC Press, Taylor & Francis, London, pp. 93-99."
  },
  {
    'id':"5",
    'point':"Shanmuga Sundar, T. Arun, M. Muneeswaran, R.Sitharthan, M. Karthikeyan, A.Sivanantha Raja, and Marcos Flores C (2020), “Conductive oxides role in Flexible Electronic Device Applications”, Metal and Metal Oxides for Energy and Electronics, Springer Nature, ISBN: 978-3-030-53065-5, pp.121-148, doi: 10.1007/978-3-030-53065-5_4."
  },
  {
    'id':"6",
    'point':"Jayson Keerthy Jayabarathan, Dr.A.Sivanantha Raja and S. Robinson, “QoS Enhancement in MANET”, Cloud and IoT based Vehicular Ad-Hoc Networks, Scrivener, Wiley Publisher, pp. 325-348, 2021, ISBN: 1119761832, 9781119761839."
  },
];

const ece_margaret=[
  {
    'id':"1",
    'point':"Vasuki, D. Helena Margaret and Rajeswari, “Microwave Engineering”, Mc Graw Hill Education (India) Pvt Ltd., 2015. (ISBN: 93-392-1948-1)",
  },
];

const ece_rajesh=[
  {
    'id':"1",
    'point':"Gopinath A, Rajesh Kanna B, Vellingiri Jayagopal, Aviral Srivastava (2023), Internet of Things (IOT), ISBN: 978-81-19489-07-7, First Edition, RK Publication, India, 21.07.2023.",
  },
];

const ece_karthika=[
  {
    'id':"1",
    'point':"Dr.K.Muralibabu, Dr.L.Agilandeeswari, Dr.C.Kathika Pragadeeswari(Assistant Professor), Embedded System and IoT Design, ISBN: 978-93-87950-89-4,First Edition, Lakshmi Publications, Chennai, India, January 2024.",
  },
];

const ece_rankers = [
  {
    head:"2019-2023 Batch",
    rankers:[
      {
        id: "1",
        cover: cover,
        dp: bharathi_23,
        name: "Bharathi P",
        Designation: "First rank holder",
        mailid: "1914008",
      },
      {
        id: "2",
        cover: cover,
        dp: sundar_23,
        name: "Sundarapandi S",
        Designation: "Second rank holder",
        mailid: "1914041",
      },
    ]
  },
  {
    head:"2018-2022 Batch",
    rankers:[
      {
        id: "1",
        cover: cover,
        dp: swetha_22,
        name: "Swetha Sri R",
        Designation: "First rank holder",
        mailid: "1814045",
      },
      {
        id: "2",
        cover: cover,
        dp: dhanaraman_22,
        name: "B.Dhanaraman",
        Designation: "Second rank holder",
        mailid: "1814014 ",
      },
    ]
  },
  {
    head:"2017-2021 Batch",
    rankers:[
      {
        id: "1",
        cover: cover,
        dp: priya_21,
        name: "Priyadharshini R",
        Designation: "First rank holder",
        mailid: "1714041",
      },
      {
        id: "2",
        cover: cover,
        dp: navin_21,
        name: "Navin Frank L",
        Designation: "Second rank holder",
        mailid: "1714035",
      },
    ]
  },
  {
    head:"2016-2020 Batch",
    rankers:[
      {
        id: "1",
        cover: cover,
        dp: aish_20,
        name: "Aishwarya B",
        Designation: "First rank holder",
        mailid: "1614002",
      },
      {
        id: "2",
        cover: cover,
        dp: sujitha_20,
        name: "Sujitha V",
        Designation: "Second rank holder",
        mailid: "1614037",
      },
    ]
  },
  
];

const ece_alumni91= [
  {
    head:"",
    rankers:[
     
   
        {
          id: "1",
          cover: cover, // Replace with actual cover
          dp: manivanan, // Replace with actual profile picture
          name: "Manivannan",
          Designation: "Senior Vice President, Engineering, Stealth AI and Data Startup",
          mailid: "Seattle, USA", // Location only
        },
        {
          id: "2",
          cover: cover, // Replace with actual cover
          dp: dpm, // Replace with actual profile picture
          name: "Janakiram Annam",
          Designation: "Founder, Hachidori Robotics",
          mailid: "Bangalore, India", // Location only
        },
        {
          id: "3",
          cover: cover, // Replace with actual cover
          dp: dpm, // Replace with actual profile picture
          name: "Venkataraman Natarajan",
          Designation: "Head of Operations – Quality, Hachidori Robotics",
          mailid: "Bangalore, India", // Location only
        },
        {
          id: "4",
          cover: cover, // Replace with actual cover
          dp: jayalakshmi, // Replace with actual profile picture
          name: "Jayalakshmi",
          Designation: "Director, Orange Sorting Machines India Pvt ltd",
          mailid: "Coimbatore, India", // Location only
        },
        {
          id: "5",
          cover: cover, // Replace with actual cover
          dp: rameshsudaram, // Replace with actual profile picture
          name: "Ramesh Sundaram",
          Designation: "Product Manager, NTT DATA",
          mailid: "Bangalore, India", // Location only
        },
        {
          id: "6",
          cover: cover, // Replace with actual cover
          dp: amuthan, // Replace with actual profile picture
          name: "Amudhan Arumugam",
          Designation: "Delivery Manager, Kumaran Systems PVT Ltd.",
          mailid: "Chennai, India", // Location only
        },
        {
          id: "7",
          cover: cover, // Replace with actual cover
          dp: dpm, // Replace with actual profile picture
          name: "Saminathan D",
          Designation: "Founder, Undisclosed Tech/Startup",
          mailid: "Los Angeles, CA", // Location only
        },
        {
          id: "8",
          cover: cover, // Replace with actual cover
          dp: dpm, // Replace with actual profile picture
          name: "Martin Ebinezer",
          Designation: "Senior Manager, Conduent",
          mailid: "Boston, MA", // Location only
        },
        {
          id: "9",
          cover: cover, // Replace with actual cover
          dp: dpm, // Replace with actual profile picture
          name: "Mani Padmanaban",
          Designation: "Senior Sales Manager, Financial Services & Investments",
          mailid: "New York", // Location only
        },
        {
          id: "10",
          cover: cover, // Replace with actual cover
          dp: dpm, // Replace with actual profile picture
          name: "Hari Nadathur",
          Designation: "Senior Manager, Salesforce",
          mailid: "Dallas, USA", // Location only
        },
        {
          id: "11",
          cover: cover, 
          dp: alagappan, 
          name: "Alagappan Krishnan",
          Designation: "Sr Director ops Engg, Flextronics",
          mailid: "Chengalpattu, India", // Location only
        },
        {
          id: "12",
          cover: cover, // Replace with actual cover
          dp: Senthilkumar, // Replace with actual profile picture
          name: "K Senthilkumar",
          Designation: "Sr Manager - Testing mgmt., Capgemini",
          mailid: "Chennai, India", // Location only
        },
        {
          id: "13",
          cover: cover, // Replace with actual cover
          dp: purijegannathan, // Replace with actual profile picture
          name: "Puri Jegannathan",
          Designation: "VP- IT Systems, PNC Bank",
          mailid: "Baden, Pennsylvania, USA", // Location only
        },
        {
          id: "14",
          cover: cover, // Replace with actual cover
          dp: dpm, // Replace with actual profile picture
          name: "V Kandasamy",
          Designation: "Sr Software Architect, Wipro technologies",
          mailid: "Bangalore, India", // Location only
        },
        {
          id: "15",
          cover: cover, // Replace with actual cover
          dp: dpm, // Replace with actual profile picture
          name: "Ramanathan Muthiah",
          Designation: "Senior DevOps Engineer, Trellix",
          mailid: "Bengaluru, India", // Location only
        },
        {
          id: "16",
          cover: cover, // Replace with actual cover
          dp: tgvenkatesh, // Replace with actual profile picture
          name: "T G Venkatesh",
          Designation: "Principal member of Technical staff, Geo Semiconductors",
          mailid: "Bengaluru, India", // Location only
        },
        {
          id: "17",
          cover: cover, // Replace with actual cover
          dp: satishthiyagarajan, // Replace with actual profile picture
          name: "Satish Thiyagarajan",
          Designation: "Co-founder & CEO, Zebra Robotics Inc",
          mailid: "Ontario, Canada", // Location only
        },
        {
          id: "18",
          cover: cover, // Replace with actual cover
          dp: rajkumar, // Replace with actual profile picture
          name: "Rajkumar R Erode",
          Designation: "Sr Director, Buckeye partners",
          mailid: "Fulshear, Texas, USA", // Location only
        },
        {
          id: "19",
          cover: cover, // Replace with actual cover
          dp: periyaswamy, // Replace with actual profile picture
          name: "Dr Periysamy Mariappan",
          Designation: "Principal member of Technical staff, Syed Ammal Engineering College",
          mailid: "Landai, Ramanathapuram district", // Location only
        },
        {
          id: "20",
          cover: cover, // Replace with actual cover
          dp: dpm, // Replace with actual profile picture
          name: "Balaji Chandrasekaran",
          Designation: "Enterprise Solutions Architect, Accenture",
          mailid: "Dallas, USA", // Location only
        },
        {
          id: "21",
          cover: cover, // Replace with actual cover
          dp: jayachitra, // Replace with actual profile picture
          name: "Dr. R K Jeyachitra",
          Designation: "Professor, National Institute of Technology",
          mailid: "Trichy, India", // Location only
        },
        {
          id: "22",
          cover: cover, // Replace with actual cover
          dp: premmurugasen, // Replace with actual profile picture
          name: "Prem Murugesan",
          Designation: "Executive Director, Wells Fargo",
          mailid: "Flowermound, Texas, USA", // Location only
        },
        {
          id: "23",
          cover: cover, // Replace with actual cover
          dp: raja, // Replace with actual profile picture
          name: "Dr B Raja Dhandapani",
          Designation: "Mathematician – Researcher, Wichita State University",
          mailid: "Kansas, USA", // Location only
        },
        {
          id: "24",
          cover: cover, // Replace with actual cover
          dp: dpm, // Replace with actual profile picture
          name: "Dr. Martin Leo Manickam",
          Designation: "Professor, ECE Department, St. Joseph College of Engineering",
          mailid: "Chennai", // Location only
        }
      ]
  }
]
const ece_alumni92=[
  {
    head:"",
    rankers:[
    
        {
          id: "1",
          cover: cover, // Replace with actual cover
          dp: dpm, // Replace with actual profile picture
          name: "Adinarayanamurthy P",
          Designation: "US – East",
          mailid: "USA", // Location only
        },
        {
          id: "2",
          cover: cover, // Replace with actual cover
          dp:dpm, // Replace with actual profile picture
          name: "Alagappan P",
          Designation: "Chennai",
          mailid: "India", // Location only
        },
        {
          id: "3",
          cover: cover, // Replace with actual cover
          dp: dpf, // Replace with actual profile picture
          name: "Anuradha R",
          Designation: "Bangalore",
          mailid: "India", // Location only
        },
        {
          id: "4",
          cover: cover, // Replace with actual cover
          dp: dpm, // Replace with actual profile picture
          name: "Dillip Kumar S",
          Designation: "US – West",
          mailid: "USA", // Location only
        },
        {
          id: "5",
          cover: cover, // Replace with actual cover
          dp: dpm, // Replace with actual profile picture
          name: "Ganesh S",
          Designation: "USA",
          mailid: "USA", // Location only
        },
        {
          id: "6",
          cover: cover, // Replace with actual cover
          dp: dpf, // Replace with actual profile picture
          name: "Gayathri Magie",
          Designation: "US – Mid",
          mailid: "USA", // Location only
        },
        {
          id: "7",
          cover: cover, // Replace with actual cover
          dp: dpm, // Replace with actual profile picture
          name: "Gnanavel",
          Designation: "Chennai",
          mailid: "India", // Location only
        },
        {
          id: "8",
          cover: cover, // Replace with actual cover
          dp:dpf, // Replace with actual profile picture
          name: "Hemalatha S",
          Designation: "US – East",
          mailid: "USA", // Location only
        },
        {
          id: "9",
          cover: cover, // Replace with actual cover
          dp: dpf, // Replace with actual profile picture
          name: "Jayabarathi S",
          Designation: "US – West",
          mailid: "USA", // Location only
        },
        {
          id: "10",
          cover: cover, // Replace with actual cover
          dp: dpm, // Replace with actual profile picture
          name: "Jayaprakash V",
          Designation: "Chennai",
          mailid: "India", // Location only
        },
        {
          id: "11",
          cover: cover, // Replace with actual cover
          dp: dpf, // Replace with actual profile picture
          name: "Karpagam S",
          Designation: "US – West",
          mailid: "USA", // Location only
        },
        {
          id: "12",
          cover: cover, // Replace with actual cover
          dp: dpm, // Replace with actual profile picture
          name: "Karunakaran D",
          Designation: "US – Mid",
          mailid: "USA", // Location only
        },
        {
          id: "13",
          cover: cover, // Replace with actual cover
          dp: dpm, // Replace with actual profile picture
          name: "Kumaran M",
          Designation: "US – East",
          mailid: "USA", // Location only
        },
        {
          id: "14",
          cover: cover, // Replace with actual cover
          dp: dpf, // Replace with actual profile picture
          name: "Lakshmi",
          Designation: "Europe",
          mailid: "UK", // Location only
        },
        {
          id: "15",
          cover: cover, // Replace with actual cover
          dp: dpf, // Replace with actual profile picture
          name: "Muthukrishnan Kalyani",
          Designation: "Bangalore",
          mailid: "India", // Lodpm
        },
        {
          id: "16",
          cover: cover, // Replace with actual cover
          dp: dpm, // Replace with actual profile picture
          name: "Muthuravishankar S",
          Designation: "US – Mid",
          mailid: "USA", // Location only
        },
        {
          id: "17",
          cover: cover, // Replace with actual cover
          dp: dpf, // Replace with actual profile picture
          name: "Ormila",
          Designation: "Chennai",
          mailid: "India", // Location only
        },
        {
          id: "18",
          cover: cover, // Replace with actual cover
          dp: dpf, // Replace with actual profile picture
          name: "Padma Venu",
          Designation: "North India",
          mailid: "India", // Location only
        },
        {
          id: "19",
          cover: cover, // Replace with actual cover
          dp: dpf, // Replace with actual profile picture
          name: "Pasu Gnanasundaram",
          Designation: "US – East",
          mailid: "USA", // Location only
        },
        {
          id: "20",
          cover: cover, // Replace with actual cover
          dp: dpf, // Replace with actual profile picture
          name: "Pushpalatha",
          Designation: "Chennai",
          mailid: "India", // Location only
        },
        {
          id: "21",
          cover: cover, // Replace with actual cover
          dp: dpf, // Replace with actual profile picture
          name: "Rajani Sunder",
          Designation: "Australia",
          mailid: "Australia", // Location only
        },
        {
          id: "22",
          cover: cover, // Replace with actual cover
          dp: dpm, // Replace with actual profile picture
          name: "Ramasubramaniam S",
          Designation: "Bangalore",
          mailid: "India", // Location only
        },
        {
          id: "23",
          cover: cover, // Replace with actual cover
          dp: dpm, // Replace with actual profile picture
          name: "Ramkumar G",
          Designation: "US – East",
          mailid: "USA", // Location only
        },
        {
          id: "24",
          cover: cover, // Replace with actual cover
          dp: dpm, // Replace with actual profile picture
          name: "Renald Abel",
          Designation: "Chennai",
          mailid: "India", // Location only
        },
        {
          id: "25",
          cover: cover, // Replace with actual cover
          dp: dpm, // Replace with actual profile picture
          name: "SathishKumar Kuppuswamy",
          Designation: "US – West",
          mailid: "USA", // Location only
        },
        {
          id: "26",
          cover: cover, // Replace with actual cover
          dp: dpm, // Replace with actual profile picture
          name: "Sathyanarayanan M",
          Designation: "Bangalore",
          mailid: "India", // Location only
        },
        {
          id: "27",
          cover: cover, // Replace with actual cover
          dp: dpf, // Replace with actual profile picture
          name: "Seetha Padmanabhan",
          Designation: "Chennai",
          mailid: "India", // Location only
        },
        {
          id: "28",
          cover: cover, // Replace with actual cover
          dp: dpm, // Replace with actual profile picture
          name: "Selvan Hitler",
          Designation: "US – West",
          mailid: "USA", // Location only
        },
        {
          id: "29",
          cover: cover, // Replace with actual cover
          dp: dpm, // Replace with actual profile picture
          name: "Senthilnathan G",
          Designation: "Chennai",
          mailid: "India", // Location only
        },
        {
          id: "30",
          cover: cover, // Replace with actual cover
          dp: dpf, // Replace with actual profile picture
          name: "Sharada P",
          Designation: "US – East",
          mailid: "USA", // Location only
        },
        {
          id: "31",
          cover: cover, // Replace with actual cover
          dp: dpf, // Replace with actual profile picture
          name: "Srimathy P",
          Designation: "Europe",
          mailid: "UK", // Location only
        },
        {
          id: "32",
          cover: cover, // Replace with actual cover
          dp: dpf, // Replace with actual profile picture
          name: "Swarnasaras",
          Designation: "Bangalore",
          mailid: "India", // Location only
        },
        {
          id: "33",
          cover: cover, // Replace with actual cover
          dp: dpf, // Replace with actual profile picture
          name: "Thaiyalnayaki S P",
          Designation: "Chennai",
          mailid: "India", // Location only
        },
        {
          id: "34",
          cover: cover, // Replace with actual cover
          dp: dpf, // Replace with actual profile picture
          name: "UshaRani",
          Designation: "US – West",
          mailid: "USA", // Location only
        },
        {
          id: "35",
          cover: cover, // Replace with actual cover
          dp: dpm, // Replace with actual profile picture
          name: "Venkataraman J",
          Designation: "North India",
          mailid: "India", // Location only
        },
        {
          id: "36",
          cover: cover, // Replace with actual cover
          dp: dpm, // Replace with actual profile picture
          name: "Venkataramanan P",
          Designation: "US – East",
          mailid: "USA", // Location only
        },
        {
          id: "37",
          cover: cover, // Replace with actual cover
          dp: dpm, // Replace with actual profile picture
          name: "Venkatesan Balasubramanian",
          Designation: "US – East",
          mailid: "USA", // Location only
        },
      ]
      
  }
]
const ece_alumni93=[


  {
    head:"",
    rankers:[
      {
        id: "1",
        cover: cover, // Replace with actual cover image
        dp: ramsankar, // Replace with actual profile picture
        name: "B Ramsankar",
        Designation: "Senior Consultant, EViDEN [Atos] Pvt. Ltd.",
        mailid: "Chennai, Tamil Nadu, India",
      },
      {
        id: "2",
        cover: cover,
        dp: sridhevi,
        name: "Dr B Sridevi",
        Designation: "Dean, Velammal Institute of Technology",
        mailid: "Chennai",
      },
      {
        id: "3",
        cover: cover,
        dp: baskaranmuthuswamy,
        name: "Baskaran Muthusamy",
        Designation: "Software Manager, KLA",
        mailid: "Chennai",
      },
      {
        id: "4",
        cover: cover,
        dp: dpm,
        name: "Venkatesh Annadurai (Venky)",
        Designation: "SVP of Technology, Patelco Credit Union",
        mailid: "California, USA",
      },
      {
        id: "5",
        cover: cover,
        dp: sivakumarnm,
        name: "Sivakumar Nm",
        Designation: "Delivery Manager, L&T Technology Services",
        mailid: "Chennai",
      },
      {
        id: "6",
        cover: cover,
        dp: dpf,
        name: "Thavamani",
        Designation: "IT Consultant, Commonwealth Bank",
        mailid: "Australia",
      },
      {
        id: "7",
        cover: cover,
        dp: srinivasan,
        name: "Srinivasan Ranganathan",
        Designation: "Chief Technology Officer, CalcFocus Inc",
        mailid: "Philadelphia, PA",
      },
      {
        id: "8",
        cover: cover,
        dp: dpm,
        name: "Mukunthan Narasimhan",
        Designation: "Executive Director, JP Morgan Chase",
        mailid: "Singapore",
      },
      {
        id: "9",
        cover: cover,
        dp: dineshbabu,
        name: "R. Dinesh Babu",
        Designation: "Pre-Si Verification Engineer, Intel Technology India Pvt Ltd",
        mailid: "Bangalore",
      },
      {
        id: "10",
        cover: cover,
        dp: dpf,
        name: "Amudha R V",
        Designation: "Director, Product Management Sourcing & Contracts, SAP Labs India Pvt Limited",
        mailid: "Bangalore",
      },
      {
        id: "11",
        cover: cover,
        dp: dpf,
        name: "K Kannika Sudharsini",
        Designation: "Deputy General Manager, Electronics Corporation of India Limited",
        mailid: "Hyderabad",
      },
      {
        id: "12",
        cover: cover,
        dp: dpm,
        name: "S. Arumugam",
        Designation: "Academic Director, Sai Sankalp Nursery and Primary School",
        mailid: "Chennai",
      },
      {
        id: "13",
        cover: cover,
        dp: dpm,
        name: "Chinthukumar V K",
        Designation: "Principal Engineer, Broadcom",
        mailid: "San Jose, California, USA",
      },
      {
        id: "14",
        cover: cover,
        dp: dpf,
        name: "Clara P",
        Designation: "Vice President, Bank of America",
        mailid: "Chennai, India",
      },
      {
        id: "15",
        cover: cover,
        dp: dpm,
        name: "R. Balamurali",
        Designation: "Co-Founder, Zeetaminds Technologies Pvt Ltd",
        mailid: "Chennai",
      },
      {
        id: "16",
        cover: cover,
        dp: dpm,
        name: "Murugaraj Narayanan",
        Designation: "Sr. Director IT & Security, Prochant",
        mailid: "Chennai",
      },
      {
        id: "17",
        cover: cover,
        dp: dpm,
        name: "Krishna Janakiraman",
        Designation: "Principal Engineer/Manager, Qualcomm",
        mailid: "San Diego",
      },
      {
        id: "18",
        cover: cover,
        dp: dpm,
        name: "K. Velmurugan",
        Designation: "Enterprise Architect, Tech Mahindra",
        mailid: "Chennai",
      },
      {
        id: "19",
        cover: cover,
        dp: dpm,
        name: "Ashok Anandh Kumar",
        Designation: "Sr. Manager, NCR Voyix",
        mailid: "Chennai",
      },
      {
        id: "20",
        cover: cover,
        dp: mohankumar,
        name: "Mohankumar Jagannathan",
        Designation: "Head of App Dev - Risk & Operations, Sculptor Capital",
        mailid: "New York, NY",
      },
      {
        id: "21",
        cover: cover,
        dp: dpm,
        name: "Balaji Ramaswamy",
        Designation: "Director of Engineering, Financial Industry Regulatory Authority",
        mailid: "Washington DC",
      },
      {
        id: "22",
        cover: cover,
        dp: Mohamedsiddique,
        name: "Mohamed Siddique",
        Designation: "Business Technology Director, Aljomiah Holding Company",
        mailid: "Riyadh, Saudi Arabia",
      },
      {
        id: "23",
        cover: cover,
        dp: dpm,
        name: "Thiagarajan Karunanithi",
        Designation: "Associate Partner, Infosys Consulting",
        mailid: "Melbourne, Australia",
      },
      {
        id: "24",
        cover: cover,
        dp: dpm,
        name: "Vijay Anand",
        Designation: "Director, Veritas Technologies",
        mailid: "Bangalore",
      },
      {
        id: "25",
        cover: cover,
        dp: dpf,
        name: "Vijaya Gurumurthi",
        Designation: "Application Specialist, SOAS, University of London",
        mailid: "London",
      },
      {
        id: "26",
        cover: cover,
        dp: dpm,
        name: "Ramkumar",
        Designation: "Software Engineer, Bank of America",
        mailid: "Chennai",
      },
      {
        id: "27",
        cover: cover,
        dp: Balasubramanian,
        name: "Balasubramanian Viswanathan",
        Designation: "Director of Technology & Fintech Site Leader, Booking Holdings India Private Limited",
        mailid: "Bangalore",
      },
      {
        id: "28",
        cover: cover,
        dp: sujaviswesan,
        name: "Suja Viswesan",
        Designation: "Vice President, IBM",
        mailid: "USA",
      }
    ]
  }
]
const ece_notablealumni=[
  {
    head: "1973 BATCH",
    rankers: [
      {
        id: "1",
        cover: cover, // Replace with actual cover image
        dp: subbhuraj,
        name: "Subburaj Ramasamy",
        Designation: "Senior Director/Scientist 'G' (Retd.), Ministry of Electronics and Information Technology, Government of India",
        mailid: "Chennai, Tamil Nadu, India"
      },
      {
        id: "2",
        cover: cover,
        dp: dpm,
        name: "Baskaran",
        Designation: "President and CEO, NELITO LTD",
        mailid: "Mumbai, Maharashtra, India"
      }
    ]
  },
  {
    head: "1977 BATCH",
    rankers: [
      {
        id: "3",
        cover: cover,
        dp: rajagopalan,
        name: "Rajagopalan Ravi",
        Designation: "Retired Manager, Product Development and QA, Aditya Medical Systems, Hyderabad",
        mailid: "Hyderabad, Telangana, India"
      },
      {
        id: "4",
        cover: cover,
        dp: nagendran,
        name: "Nagendran D",
        Designation: "Retired Principal, V.S.V.N Polytechnic College, Virudhunagar",
        mailid: "Virudhunagar, Tamil Nadu, India"
      },
      {
        id: "5",
        cover: cover,
        dp: dittakavi,
        name: "Dittakavi Suryanarayanan",
        Designation: "Retired Manager/Centre Head, ICFAI/Emitac, UAE-Mustfa Sultan S&I, Oman",
        mailid: "Oman"
      },
      {
        id: "6",
        cover: cover,
        dp: dhinakaran,
        name: "Dr R Dhinakaran Samuel",
        Designation: "Professor Emirates, Loyola Institute of Technology, Chennai",
        mailid: "Chennai, Tamil Nadu, India"
      },
      {
        id: "7",
        cover: cover,
        dp: balashanmuga,
        name: "R.P. Bala Shanmuga Sundaram",
        Designation: "Retired AGM, Bharat Electronics Ltd",
        mailid: "Bengaluru, Karnataka, India"
      },
      {
        id: "8",
        cover: cover,
        dp: manohar,
        name: "S. Manohar",
        Designation: "AddI Member /Railway Board (Rtd.), Consultant, Design Engineer, DFCCIL",
        mailid: "Delhi, India"
      }
    ]
  },
  {
    head: "1978 BATCH",
    rankers: [
      {
        id: "9",
        cover: cover,
        dp: muthu,
        name: "K Muthu",
        Designation: "Senior Principal Scientist (Retd.), CSIR-CECRI",
        mailid: "Karaikudi, Tamil Nadu, India"
      }
    ]
  },
 
    {
      head: "1980 BATCH",
      rankers: [
        {
          id: "1",
          cover: cover,
          dp: narayana,
          name: "Narayana Manickavasagam",
          Designation: "Data Architect",
          mailid: "NT Government, Australia"
        },
        {
          id: "2",
          cover: cover,
          dp: bharathiradha,
          name: "J Bharathi Radha",
          Designation: "Chief Engineer (Electronics and Communication)",
          mailid: "Oil and Natural Gas Corporation Ltd"
        },
        {
          id: "3",
          cover: cover,
          dp: subbiahrm,
          name: "Subbiah R M",
          Designation: "Chief General Manager (Retd.)",
          mailid: "Department of Telecom"
        }
      ]
    },
    {
      head: "1983 BATCH",
      rankers: [
        {
          id: "4",
          cover: cover,
          dp: dakshinamurthy,
          name: "A Dakshinamurthy",
          Designation: "Chief General Manager (E&T) (Retd.)",
          mailid: "Oil & Natural Gas Corporation Ltd (A Govt of India U/T)"
        }
      ]
    },
    {
      head: "1984 BATCH",
      rankers: [
        {
          id: "5",
          cover: cover,
          dp: senthilanathanpra,
          name: "Senthil Anandha Prakash J",
          Designation: "CEO",
          mailid: "Prakash Motors, Sivakasi"
        }
      ]
    },
    {
      head: "1987 BATCH",
      rankers: [
        {
          id: "6",
          cover: cover,
          dp: sivanantharaja,
          name: "Sivanantha Raja A",
          Designation: "Professor of ECE",
          mailid: "Alagappa Chettiar College of Engineering and Technology, Karaikudi"
        }
      ]
    },
    {
      head: "1988 BATCH",
      rankers: [
        {
          id: "7",
          cover: cover,
          dp: srikanth,
          name: "Srikanth Ramachandran",
          Designation: "Founder CEO",
          mailid: "Moving Walls Pte Ltd"
        }
      ]
    },
    {
      head: "1989 BATCH",
      rankers: [
        {
          id: "8",
          cover: cover,
          dp: selvakumarraja,
          name: "Dr S Selvakumar Raja",
          Designation: "Principal",
          mailid: "Kakatiya Institute of Technology and Science for Women, Nizamabad, Telangana"
        },
        {
          id: "9",
          cover: cover,
          dp: ramesh,
          name: "Ramesh M",
          Designation: "Self Employed",
          mailid: "Sandeep Systems"
        },
        {
          id: "10",
          cover: cover,
          dp: ramaiah,
          name: "Ramaiah S",
          Designation: "Vice President",
          mailid: "HCL Tech"
        }
      ]
    },
    {
      head: "1990 BATCH",
      rankers: [
        {
          id: "11",
          cover: cover,
          dp: Balasubramanian,
          name: "Dr R Balasubramani",
          Designation: "Professor in ISE",
          mailid: "NMAM Institute of Technology (NITTE Deemed-to-be-University), Nitte, Karnataka"
        },
        {
          id: "12",
          cover: cover,
          dp: chidambaram,
          name: "Chidambaram M",
          Designation: "Country Head",
          mailid: "Relevance Lab Pvt Ltd"
        }
      ]
    },
    {
      head: "1991 BATCH",
      rankers: [
        {
          id: "13",
          cover: cover,
          dp: komalavalli,
          name: "Dr C Komalavalli",
          Designation: "Professor",
          mailid: "Presidency University, Bengaluru"
        },
        {
          id: "14",
          cover: cover,
          dp: dpm,
          name: "Janakiraman Muthusamy",
          Designation: "Assistant Engineer",
          mailid: "Doordarshan Kendra, Mumbai"
        }
      ]
    },
    {
      head: "1992 BATCH",
      rankers: [
        {
          id: "15",
          cover: cover,
          dp: venkataraghavan,
          name: "Venkataraghavan PK",
          Designation: "Director of Engineering",
          mailid: "Infineon Technologies, USA"
        },
        {
          id: "16",
          cover: cover,
          dp: thirumaraiselvan,
          name: "Thirumaraiselvan",
          Designation: "Professor of ECE",
          mailid: "Adhiparasakthi Engineering College, Melmaruvathur"
        }
      ]
    },
    {
      head: "1993 BATCH",
      rankers: [
        {
          id: "17",
          cover: cover,
          dp: sudhakar,
          name: "Sudhakar VR",
          Designation: "Director",
          mailid: "RM Applications, Malaysia"
        }
      ]
    },
    {
      head: "1995 BATCH",
      rankers: [
        {
          id: "18",
          cover: cover,
          dp: sivashankar,
          name: "Siva Shankar",
          Designation: "Senior Manager",
          mailid: "Sony Pictures Network India Pvt Ltd"
        }
      ]
    },
    {
      head: "1997 BATCH",
      rankers: [
        {
          id: "19",
          cover: cover,
          dp: baskaranmuthuswamy,
          name: "Baskaran Muthusamy",
          Designation: "Software Manager",
          mailid: "KLA"
        }
      ]
    },
    {
      head: "1999 BATCH",
      rankers: [
        {
          id: "20",
          cover: cover,
          dp: ganesh,
          name: "Ganesh CS",
          Designation: "Computer Scientist",
          mailid: "Information Sciences Institute"
        },
        {
          id: "21",
          cover: cover,
          dp: tamilmanan,
          name: "Tamilmanam Sambasivam",
          Designation: "Solutions Architect",
          mailid: "Amazon"
        }
      ]
    },
    {
      head: "2000 BATCH",
      rankers: [
        {
          id: "22",
          cover: cover,
          dp: manikandan,
          name: "Manikandan Raghavan",
          Designation: "IT Consultant",
          mailid: "Private Organization"
        },
        {
          id: "23",
          cover: cover,
          dp: Ponnalagu,
          name: "RN Ponnalagu",
          Designation: "Associate Professor",
          mailid: "BITS Pilani Hyderabad Campus"
        },
        {
          id: "24",
          cover: cover,
          dp: kumaraguru,
          name: "Kumaraguru Narayanan",
          Designation: "Director",
          mailid: "Securden Technology"
        }
      ]
    },
    {
      head: "2002 BATCH",
      rankers: [
        {
          id: "1",
          cover: cover,
          dp: ramkumarR,
          name: "Ramkumar R",
          Designation: "Senior Technologist",
          mailid: "Western Digital"
        }
      ]
    },
    {
      head: "2005 BATCH",
      rankers: [
        {
          id: "2",
          cover: cover,
          dp: velupalani,
          name: "Velu Palani",
          Designation: "Enterprise Data Architect",
          mailid: "Johnson Controls"
        },
        {
          id: "3",
          cover: cover,
          dp: saravanaMohan,
          name: "Saravanan Mohan",
          Designation: "Director of Software Engineering",
          mailid: "Trellix"
        },
        {
          id: "4",
          cover: cover,
          dp: karthi,
          name: "Karthik K",
          Designation: "Senior Software Engineer Manager",
          mailid: "Juniper Network"
        }
      ]
    },
    {
      head: "2006 BATCH",
      rankers: [
        {
          id: "5",
          cover: cover,
          dp: berlinsharmila,
          name: "Berlin Sharmila Manickam",
          Designation: "Manager",
          mailid: "Cognizant Technology Solutions"
        }
      ]
    },
    {
      head: "2007 BATCH",
      rankers: [
        {
          id: "6",
          cover: cover,
          dp: naveen,
          name: "Naveen Sam T",
          Designation: "Associate Consultant",
          mailid: "Tata Consultancy Service"
        },
        {
          id: "7",
          cover: cover,
          dp: gokul,
          name: "Gokul Ramamoorthy",
          Designation: "Senior Analyst",
          mailid: "DXC Technology"
        }
      ]
    },
    {
      head: "2010 BATCH",
      rankers: [
        {
          id: "8",
          cover: cover,
          dp: pitchai,
          name: "Pitchai Raman M",
          Designation: "Manager",
          mailid: "Canara Bank"
        },
        {
          id: "9",
          cover: cover,
          dp: priyaDharshini,
          name: "Priyadharshini Jeganathan",
          Designation: "Test Manager",
          mailid: "Accenture"
        }
      ]
    },
    {
      head: "2011 BATCH",
      rankers: [
        {
          id: "10",
          cover: cover,
          dp: srinivasanallaiya,
          name: "Srinivasa Nallaiya",
          Designation: "Associate Consultant",
          mailid: "Tata Consultancy Service"
        },
        {
          id: "11",
          cover: cover,
          dp: thackor,
          name: "Thackor Singh",
          Designation: "Assistant Vice President",
          mailid: "Citi"
        }
      ]
    },
    {
      head: "2012 BATCH",
      rankers: [
        {
          id: "12",
          cover: cover,
          dp: govindamoorthi,
          name: "Govindamoorthi P",
          Designation: "Engineering Assistant",
          mailid: "Southern Railways"
        }
      ]
    },
    {
      head: "2015 BATCH",
      rankers: [
        {
          id: "13",
          cover: cover,
          dp: sowbharnika,
          name: "Sowbharanika Kumar K",
          Designation: "Chief Marketing Officer",
          mailid: "Musquare Technologies Private Ltd"
        }
      ]
    },
    {
      head: "2017 BATCH",
      rankers: [
        {
          id: "14",
          cover: cover,
          dp: mathuravani,
          name: "Madhuravani",
          Designation: "Design Verification Engineer",
          mailid: "PravegaSemi Pvt Ltd"
        },
        {
          id: "15",
          cover: cover,
          dp: durga,
          name: "Durga Priyadharshini",
          Designation: "IT Analyst",
          mailid: "TCS"
        }
      ]
    },
    {
      head: "2019 BATCH",
      rankers: [
        {
          id: "16",
          cover: cover,
          dp: ponnumani,
          name: "Ponnumani M",
          Designation: "Customer Service Associate",
          mailid: "Bank Of India"
        }
      ]
    },
    {
      head: "2020 BATCH",
      rankers: [
        {
          id: "17",
          cover: cover,
          dp: roja,
          name: "Roja K S",
          Designation: "Life Cycle Information Specialist",
          mailid: "Hitachi Energy"
        },
        {
          id: "18",
          cover: cover,
          dp: kana,
          name: "Kanna K",
          Designation: "Software Developer",
          mailid: "ZOHO"
        }
      ]
    },
    {
      head: "2021 BATCH",
      rankers: [
        {
          id: "19",
          cover: cover,
          dp: sheik,
          name: "Shiek Ashik S",
          Designation: "Software Developer",
          mailid: "NielsenIQ"
        },
        {
          id: "20",
          cover: cover,
          dp: meenambal,
          name: "Meenambal M",
          Designation: "Data Engineer",
          mailid: "Wavicle Data Solutions, Coimbatore"
        },
        {
          id: "21",
          cover: cover,
          dp: navin,
          name: "Navin Frank L",
          Designation: "Member Technical Staff",
          mailid: "ZOHO Corporation"
        }
      ]
    },
    {
      head: "2023 BATCH",
      rankers: [
        {
          id: "22",
          cover: cover,
          dp: partha,
          name: "Parthasarathi",
          Designation: "AWS Solution Architect",
          mailid: "JETZERP Pvt Ltd"
        }
      ]
    },
    {
      head: "2024 BATCH",
      rankers: [
        {
          id: "23",
          cover: cover,
          dp: ramkumarB,
          name: "Ramkumar B",
          Designation: "Quality Analyst",
          mailid: "ZOHO Corporation"
        },
        {
          id: "24",
          cover: cover,
          dp: eswaran,
          name: "Eswaran KP",
          Designation: "Member Technical Staff",
          mailid: "ZOHO"
        },
        {
          id: "25",
          cover: cover,
          dp: surya,
          name: "Surya UR",
          Designation: "Java Developer",
          mailid: "Alpharithm Technologies Pvt Ltd, Adyar"
        },
        {
          id: "26",
          cover: cover,
          dp: mahadevi,
          name: "Mahadevi T",
          Designation: "Management Trainee",
          mailid: "JETZERP Pvt Ltd"
        },
        {
          id: "27",
          cover: cover,
          dp: vijaypriya,
          name: "Vijayapriya V",
          Designation: "Graduate Engineer Trainee",
          mailid: "Schneider Electric India Pvt Ltd"
        },
        {
          id: "28",
          cover: cover,
          dp: gokul,
          name: "Gokul R",
          Designation: "Technical Support",
          mailid: "Zencommerce India"
        }
      ]
    }
  
      
      
  
];
const ece_alumni=[
  {
  'id':'1',
  'program':'1991 - 1995 BATCH',
  'click':'/ecedept/ece-alumni91'
  },
  {
    'id':'2',
  'program':'1992-1996 BATCH',
  'click':'/ecedept/ece-alumni92'
  },
  {
    'id':'3',
  'program':'1993-1997 BATCH',
  'click':'/ecedept/ece-alumni93'
  },
  {
    'id':'4',
  'program':'NOTABLE ALUMNI',
  'click':'/ecedept/ece-alumninotable'
  }
];
const ece_vision = {
  id: "1",
  visiondata:
    "To empower electronics and communication engineering students with academic excellence, professional ethics and entrepreneurial skills to contribute to the society at global standards.",
};

const ece_mission = [
  {
    id: "1",
    mission:
      "To attain academic excellence in Electronics and  Communication  Engineering through innovation in learning, research and state of the art laboratories.",
  },
  {
    id: "2",
    mission:
      "To  establish  suitable  environment  for  the  students  to  develop  professionalism, leadership qualities and entrepreneurial skills with high ethical standards for self and social development.",
  },
  {
    id: "3",
    mission:
      "To  equip  the  students  to  provide  appropriate  solutions  with  their  technological expertise to address the societal needs.",
  },
];

const ece_peo = [
  {
    id: "1",
    head: "PEO 1",
    house: "left",
    content:
      "After completion of the course, the Graduates will be able to develop design procedures, prototypes and test methodologies for modern Electronics and Communication Systems. ",
  },
  {
    id: "2",
    head: "PEO 2",
    house: "right",
    content:
      "After completion of the course, the Graduates will be able to work and adapt to changes in allied areas of Electronics and Communication Engineering through personal success and lifelong learning.",
  },
  {
    id: "3",
    head: "PEO 3",
    house: "left",
    content:
      "After completion of the course, the Graduates will be able to recognize technological requirements for the society and provide cost effective solutions.",
  },
];

const ece_outcomes = [
  {
    id: "1",
    point:
      "Engineering knowledge: Apply the knowledge of mathematics, science, engineering fundamentals and an engineering specialization to the solution of complex engineering problems.",
  },
  {
    id: "2",
    point:
      "Problem analysis: Identify, formulate, review research literature, and analyze complex engineering problems reaching substantiated conclusions using first principles of mathematics, natural sciences and engineering sciences",
  },
  {
    id: "3",
    point:
      "Design/development of solutions: Design solutions for complex engineering problems and design system components or processes that meet the specified needs with appropriate consideration fr the public health and safety, and the cultural, societal, and environmental considerations.",
  },
  {
    id: "4",
    point:
      "Conduct investigations of complex problems: Use research-based knowledge and research methods mcluding design of experiments, analysis and interprctation of data, and synthesis of the information to provide valid conclusions.",
  },
  {
    id: "5",
    point:
      "Modem tool usage: Create, select, and apply appropriate techniques, resources, and modem engineering and IT tools including prediction and modeling to complex engineering activities with an understanding of the limitations.",
  },
  {
    id: "6",
    point:
      "The engineer and society: Apply reasoning informed by the contextual knowledge to assess social health, safety, legal and cultural issues and the consequent responsibilities relevant to the professional engineering practice.",
  },
  {
    id: "7",
    point:
      "Environment and sustainability: Understand the impact of the professional engineering solutions in societal and environmental contexts, and demonstrate the knowledge of, and need for sustainable development.",
  },
  {
    id: "8",
    point:
      "Ethics: Apply ethical principles and commit to professional ethics and responsibilities and norms of the engineering practice.",
  },
  {
    id: "9",
    point:
      "Individual and team work: Function effectively as an individual, and as a member or leader in diverse teams, and in multidisciplinary settings.",
  },
  {
    id: "10",
    point:
      "Communication: Communicate effectively on complex engineering activities with the engineering community and with society at large, such as, being able to comprehend and write effective reports and design documentation, make effective presentations, and give and receive clear instructions.",
  },
  {
    id: "11",
    point:
      "Project management and finance: Demonstrate knowledge and understanding of the engineering and management principles and apply these to one's own work, as a member and leader in a team to manage projects and in multidisciplinary environments.",
  },
  {
    id: "12",
    point:
      "Life-long learning: Recognize the need for, and have the preparation and ability to engage in independent and life-long learning in the broadest context of technological change.",
  },
];

const ece_spec_outcomes = [
  {
    id: "1",
    point:
      "ECE Graduates will Analyze, Synthesize, design and provide an engineering solution in the areas of VLSI, Image Processing and Embedded Systems.",
  },
  {
    id: "2",
    point:
      "ECE Graduates will Analyze, design and provide an engineering solution in the areas of RF and Microwaves, Wireless and Optical Communication.",
  },
];

const ece_progs = [
  {
    id: "1",
    year: "1968",
    period: "4 Years",
    top_head: "B.E.",
    program: "Electronics and Communication Engineering",
    intake: "60",
  },
  {
    id: "2",
    year: "2002",
    period: "2 Years",
    top_head: "M.E.",
    program: "Microwave and Optical Communication",
    intake: "18",
  },
  {
    id: "3",
    year: "2002",
    period: "4 Years",
    top_head: "Part time B.E.",
    program: "Electronics and Communication Engineering",
    intake: "60",
  },
];

const ece_stud_data = [
  {
    
    id: "1",
    heading: "2rd Year",
    component: "table",
    structure: {
      data: [
          {
            sno: "1",
            regno: "91762314001",
            name: "AAKASH J"
          },
          {
            sno: "2",
            regno: "91762314002",
            name: "P.Adhithyan"
          },
          {
            sno: "3",
            regno: "91762314003",
            name: "ALAGAR G"
          },
          {
            sno: "4",
            regno: "91762314004",
            name: "A Anishma"
          },
          {
            sno: "5",
            regno: "91762314005",
            name: "ARPUTHAN K"
          },
          {
            sno: "6",
            regno: "91762314006",
            name: "ARUNTHATHI.S"
          },
          {
            sno: "7",
            regno: "91762314007",
            name: "Bathrinath sk"
          },
          {
            sno: "8",
            regno: "91762314008",
            name: "A.Blessy"
          },
          {
            sno: "9",
            regno: "91762314009",
            name: "Deepika M"
          },
          {
            sno: "10",
            regno: "91762314010",
            name: "Deva Dharshini.P"
          },
          {
            sno: "11",
            regno: "91762314011",
            name: "DHARSHINI S"
          },
          {
            sno: "12",
            regno: "91762314012",
            name: "Dinesh G"
          },
          {
            sno: "13",
            regno: "91762314013",
            name: "L. Gayathri"
          },
          {
            sno: "14",
            regno: "91762314015",
            name: "Gobish V"
          },
          {
            sno: "15",
            regno: "91762314016",
            name: "M. Gokulnath"
          },
          {
            sno: "16",
            regno: "91762314017",
            name: "HARIHESAN M"
          },
          {
            sno: "17",
            regno: "91762314018",
            name: "Iniya.J"
          },
          {
            sno: "18",
            regno: "91762314019",
            name: "Ishwarya Lakshmi A"
          },
          {
            sno: "19",
            regno: "91762314020",
            name: "Jaya S"
          },
          {
            sno: "20",
            regno: "91762314021",
            name: "JOHN JENIT J"
          },
          {
            sno: "21",
            regno: "91762314022",
            name: "KATHIR S"
          },
          {
            sno: "22",
            regno: "91762314023",
            name: "KAVIYA S"
          },
          {
            sno: "23",
            regno: "91762314024",
            name: "Lakshimi Priya V"
          },
          {
            sno: "24",
            regno: "91762314025",
            name: "S.Lalitha"
          },
          {
            sno: "25",
            regno: "91762314026",
            name: "MANOJ S"
          },
          {
            sno: "26",
            regno: "91762314027",
            name: "Merlin Janet .P"
          },
          {
            sno: "27",
            regno: "91762314028",
            name: "Mohamed Abdul Kadhar. N"
          },
          {
            sno: "28",
            regno: "91762314029",
            name: "MUGILAN M"
          },
          {
            sno: "29",
            regno: "91762314030",
            name: "MUTHUPANDI M"
          },
          {
            sno: "30",
            regno: "91762314031",
            name: "Muthupriya G"
          },
          {
            sno: "31",
            regno: "91762314032",
            name: "T.Nandhini Devi"
          },
          {
            sno: "32",
            regno: "91762314034",
            name: "PRANEETH. T"
          },
          {
            sno: "33",
            regno: "91762314035",
            name: "Preba M"
          },
          {
            sno: "34",
            regno: "91762314036",
            name: "Priyadharshini P"
          },
          {
            sno: "35",
            regno: "91762314037",
            name: "RAJADURAI M"
          },
          {
            sno: "36",
            regno: "91762314038",
            name: "RAVIBALAN R"
          },
          {
            sno: "37",
            regno: "91762314039",
            name: "Riya Rathna P V"
          },
          {
            sno: "38",
            regno: "91762314040",
            name: "ROHITHKUMAR C"
          },
          {
            sno: "39",
            regno: "91762314041",
            name: "SAKTHI PRIYAN V"
          },
          {
            sno: "40",
            regno: "91762314042",
            name: "Sanjai G"
          },
          {
            sno: "41",
            regno: "91762314043",
            name: "Shalini R"
          },
          {
            sno: "42",
            regno: "91762314044",
            name: "Sivasankaran p"
          },
          {
            sno: "43",
            regno: "91762314045",
            name: "K. Sri Krishna"
          },
          {
            sno: "44",
            regno: "91762314046",
            name: "Subalakshmi N"
          },
          {
            sno: "45",
            regno: "91762314047",
            name: "Subashini.T"
          },
          {
            sno: "46",
            regno: "91762314048",
            name: "A sumithkumar"
          },
          {
            sno: "47",
            regno: "91762314049",
            name: "Uma Rohini S"
          },
          {
            sno: "48",
            regno: "91762314050",
            name: "K. Varusharaja"
          },
          {
            sno: "49",
            regno: "91762314051",
            name: "VASANTHAKUMAR A"
          },
          {
            sno: "50",
            regno: "91762314052",
            name: "Vinshika B"
          },
          {
            sno: "51",
            regno: "91762314053",
            name: "Vishal K"
          },
          {
            sno: "52",
            regno: "91762314054",
            name: "Yuvasri G"
          },
          {
            sno: "53",
            regno: "91762414201",
            name: "Anvar M A"
          },
          {
            sno: "54",
            regno: "91762414202",
            name: "Gokulakrishnan S"
          },
          {
            sno: "55",
            regno: "91762414203",
            name: "Hariharan A"
          },
          {
            sno: "56",
            regno: "91762414204",
            name: "Keerthana S B"
          },
          {
            sno: "57",
            regno: "91762414205",
            name: "Mugilan P"
          },
          {
            sno: "58",
            regno: "91762414206",
            name: "Nithish Kumar S"
          },
        ],
        columns: [
          { field: "sno", header: "sno" },
          { field: "regno", header: "Register No" },
          { field: "name", header: "name" },
        ],
      },
    
    
  },
  {
    id: "2",
    heading: "3rd Year",
    component: "table",
    structure: {
      data: [
        {
          sno: "1",
          regno:"91762214001",
          name: "ABINAYA B",
        },
        {
          sno: "2",
          regno:"91762214002",
          name: "AJEES M",
        },
        {
          sno: "3",
          regno:"91762214003",
          name: "AKASH BABU L",
        },
        {
          sno: "4",
          regno:"91762214004",
          name: "ARCHANA PARAMESHWARI S",
        },
        {
          sno: "5",
          regno:"91762214005",
          name: "ARUN KARTHICK S",
        },
        // {
        //   sno: "6",
        //   regno:"91762214006",
        //   name: "BALAJI R G",
        // },
        {
          sno: "6",
          regno:"91762214007",
          name: "DHANAPRASATH K",
        },
        {
          sno: "7",
          regno:"91762214008",
          name: "DHARANI H",
        },
        {
          sno: "8",
          regno:"91762214009",
          name: "DINESH SUBRAMANIAN G",
        },
        {
          sno: "9",
          regno:"917622140010",
          name: "DIVYA DHARSHINI N",
        },

        {
          sno: "10",
          regno:"91762214011",
          name: "DIVYASREE M",
        },
        {
          sno: "11",
          regno:"91762214012",
          name: "DURGA A",
        },
        {
          sno: "12",
          regno:"91762214013",
          name: "GAYATHRIDEVI M",
        },
        {
          sno: "13",
          regno:"91762214014",
          name: "GEETHAPRIYA M",
        },
        {
          sno: "14",
          regno:"91762214015",
          name: "GOBIKA P",
        },
        {
          sno: "15",
          regno:"91762214016",
          name: "HAREL J D",
        },
        {
          sno: "16",
          regno:"917622140018",
          name: "HARIPRIYA M",
        },
        {
          sno: "17",
          regno:"91762214019",
          name: "JANANI KAMATCHI S",
        },
        {
          sno: "18",
          regno:"91762214020",
          name: "JANANI V",
        },
        {
          sno: "19",
          regno:"917622140021",
          name: "JAYASRI R",
        },

        // {
        //     sno: "21",
        //     regno:"91762214022",
        //     name: "JAYASURYA P",
        //   },
          {
            sno: "20",
            regno:"91762214024",
            name: "KARUPPASAMY ALIAS SARAVANAVEL S",
          },
          {
            sno: "21",
            regno:"91762214025",
            name: "KEERTHANA V",
          },
          {
            sno: "22",
            regno:"91762214026",
            name: "KEERTHIKA R",
          },
          {
            sno: "23",
            regno:"91762214027",
            name: "KRISHNA KANTH PA",
          },
          {
            sno: "24",
            regno:"91762214028",
            name: "LOGESH A",
          },
          {
            sno: "25",
            regno:"91762214029",
            name: "MADHUMITHA M",
          },
          {
            sno: "26",
            regno:"91762214030",
            name: "MALINI M",
          },
          {
            sno: "27",
            regno:"91762214031",
            name: "MEERA DEVI A",
          },
          {
            sno: "28",
            regno:"91762214032",
            name: "MOHANADHARSHINI V",
          },

          {
            sno: "29",
            regno:"91762214033",
            name: "MUGESH KUMAR S",
          },
          {
            sno: "30",
            regno:"91762214034",
            name: "NANDHINI S ",
          },
          {
            sno: "31",
            regno:"91762214035",
            name: "NAVEENA NAGARAJAN",
          },
          {
            sno: "32",
            regno:"91762214036",
            name: "NIDYA SRI KAMATCHI B",
          },
          // {
          //   sno: "35",
          //   regno:"91762214037",
          //   name: "PADMANABAN R",
          // },
          {
            sno: "33",
            regno:"91762214038",
            name: "POORNIMA SRI D",
          },
          {
            sno: "34",
            regno:"91762214039",
            name: "RAJAVARMAN A",
          },
          {
            sno: "35",
            regno:"91762214040",
            name: "RAMYA K",
          },
          // {
          //   sno: "39",
          //   regno:"91762214041",
          //   name: "RAMYA S",
          // },
          {
            sno: "36",
            regno:"91762214042",
            name: "SANDHIYA P",
          },

          {
            sno: "37",
            regno:"91762214043",
            name: "SATHISHKUMAR K",
          },
          {
            sno: "38",
            regno:"91762214044",
            name: "SELCIKA M",
          },
          {
            sno: "39",
            regno:"91762214045",
            name: "SHAHID AFFAN S",
          },
          {
            sno: "40",
            regno:"91762214047",
            name: "SIVAKUMAR M",
          },
          {
            sno: "41",
            regno:"91762214048",
            name: "SOZHARAJAN V",
          },
          {
            sno: "42",
            regno:"91762214049",
            name: "SRINIDHI R",
          },
          {
            sno: "43",
            regno:"91762214050",
            name: "SUBHASHINI P",
          },
          {
            sno: "44",
            regno:"91762214051",
            name: "SUJITHA S",
          },
          {
            sno: "45",
            regno:"91762214052",
            name: "SUNILKUMAR M",
          },
          {
            sno: "46",
            regno:"91762214053",
            name: "THAMARAI SELVI E",
          },
  
          {
            sno: "47",
            regno:"91762214054",
            name: "THIRUVENGADAM S",
          },
          {
            sno: "48",
            regno:"91762214055",
            name: "VIMANTHAN K G  ",
          },
          {
            sno: "49",
            regno:"91762214056",
            name: "YUVARAJ S",
          },
          {
            sno: "50",
            regno:"91762314201",
            name: "ARUNSANKAR N",
          },
          {
            sno: "51",
            regno:"91762314202",
            name: "HARIPRASATH T",
          },
          {
            sno: "52",
            regno:"91762314203",
            name: "SANTHOSH R",
          },
          {
            sno: "53",
            regno:"91762314204",
            name: "SHAIRANJANI T",
          },
          {
            sno: "54",
            regno:"91762314205",
            name: "SHANJAY VIGASH RAJ V",
          },
          {
            sno: "55",
            regno:"91762214301",
            name: "HARISH RAJ S",
          },
          {
            sno: "56",
            regno:"91762214302",
            name: "LAKSHMI SRI L",
          },
          {
            sno: "57",
            regno:"91762214303",
            name: "PREETHI S",
          },
          {
            sno: "58",
            regno:"91762214304",
            name: "SHYAM A",
          },
      ],
      columns: [
        { field: "sno", header: "sno" },
        { field: "regno", header: "Register No" },
        { field: "name", header: "name" },
      ],
    },
  },


  {
    id: "3",
    heading: "4th Year",
    component: "table",
    structure: {
      data: [
        {
          sno: "1",
          regno:"91762114001",
          name: "AARTHY K",
        },
        {
          sno: "2",
          regno:"91762114002",
          name: "AKASH P",
        },
        {
          sno: "3",
          regno:"91762114003",
          name: "AKILA A",
        },
        {
          sno: "4",
          regno:"91762114005",
          name: "ANISH A",
        },
        {
          sno: "5",
          regno:"91762114006",
          name: "ARUNKUMAR N",
        },
        {
          sno: "6",
          regno:"91762114007",
          name: "ASEENA SAFAN A",
        },
        {
          sno: "7",
          regno:"91762114008",
          name: "BHARATHKUMAR M",
        },
        {
          sno: "8",
          regno:"91762114009",
          name: "BLESSLIN RINU P",
        },
        {
          sno: "9",
          regno:"91762114010",
          name: "DHANANJAI M V",
        },
        {
          sno: "10",
          regno:"91762114013",
          name: "GOKULAKRISHNAN G",
        },

        {
          sno: "11",
          regno:"91762114014",
          name: "JAYASRI P",
        },
        {
          sno: "12",
          regno:"91762114015",
          name: "JEEVANANTHAM V",
        },
        {
          sno: "13",
          regno:"91762114017",
          name: "KAILASH T S",
        },
        {
          sno: "14",
          regno:"91762114019",
          name: "KARTHIKEYAN S",
        },
        {
          sno: "15",
          regno:"91762114020",
          name: "KAVINAYASRI M",
        },
        {
          sno: "16",
          regno:"91762114023",
          name: "KOKILA P",
        },
        {
          sno: "17",
          regno:"91762114024",
          name: "MASILAMANI S",
        },
        {
          sno: "18",
          regno:"91762114025",
          name: "MITHRA E",
        },
        {
          sno: "19",
          regno:"91762114026",
          name: "MONISH S",
        },
        {
          sno: "20",
          regno:"91762114027",
          name: "MOUNIKA S",
        },

        {
          sno: "21",
          regno:"91762114028",
          name: "NAGARAJAN K",
        },
        {
          sno: "22",
          regno:"91762114030",
          name: "PARAMASIVAM R",
        },
        {
          sno: "23",
          regno:"91762114031",
          name: "PRAVIN K S",
        },
        {
          sno: "24",
          regno:"91762114032",
          name: "PRIYANKA A L",
        },
        {
          sno: "25",
          regno:"91762114033",
          name: "PRIYANKA M",
        },
        {
          sno: "26",
          regno:"91762114034",
          name: "RAMA A",
        },
        {
          sno: "27",
          regno:"91762114035",
          name: "SABARI JOTHI I",
        },
        {
          sno: "28",
          regno:"91762114036",
          name: "SAMYUKTHA S",
        },
        {
          sno: "29",
          regno:"91762114037",
          name: "SANMUGAPRIYA T",
        },
        {
          sno: "30",
          regno:"91762114038",
          name: "SANTHOSH R",
        },

        {
          sno: "31",
          regno:"91762114041",
          name: "SIVASANKARI M",
        },
        {
          sno: "32",
          regno:"91762114042",
          name: "SOWMIYA D",
        },
        {
          sno: "33",
          regno:"91762114043",
          name: "SRIMUNIPRIYA K",
        },
        {
          sno: "34",
          regno:"91762114044",
          name: "SURYA R",
        },
        {
          sno: "35",
          regno:"91762114045",
          name: "SWEKA S",
        },
        {
          sno: "36",
          regno:"91762114046",
          name: "UDHAYAVARSHINI R",
        },
        {
          sno: "37",
          regno:"91762114047",
          name: "VASANTHAMALAR J",
        },
        {
          sno: "38",
          regno:"91762114049",
          name: "VIGNESHWARI M",
        },
        {
          sno: "39",
          regno:"91762214009",
          name: "VIKNESHRAJA M",
        },
        {
          sno: "40",
          regno:"91762114051",
          name: "VINODHINI R",
        },

        {
          sno: "41",
          regno:"91762114052",
          name: "VIVIAN ROHITH SURYAA J",
        },
        {
          sno: "42",
          regno:"91762114301",
          name: "HOSHIKA DEVI M",
        },
        {
          sno: "43",
          regno:"91762114302",
          name: "KAVYA M",
        },
        {
          sno: "44",
          regno:"91762114303",
          name: "MALAIARASAN P",
        },
        {
          sno: "45",
          regno:"91762114304",
          name: "MANIKANDAN E",
        },
        {
          sno: "46",
          regno:"91762214201",
          name: "ABHIMANYU P",
        },
        {
          sno: "47",
          regno:"91762214202",
          name: "AZHAGU SELVAM C",
        },
        {
          sno: "48",
          regno:"91762214203",
          name: "DHANUMOORTHY S",
        },
        {
          sno: "49",
          regno:"91762214204",
          name: "DIVYA M",
        },
        {
          sno: "50",
          regno:"91762214206",
          name: "POOJA M",
        },

        {
          sno: "51",
          regno:"91762214207",
          name: "PRIYANKA SHANTHINI G",
        },
        {
          sno: "52",
          regno:"91762214208",
          name: "SRIKANTH I",
        },
        
      ],
      columns: [
        { field: "sno", header: "sno" },
        { field: "regno", header: "Register No" },
        { field: "name", header: "name" },
      ],
    },
  },


];

const ece_acad_labs = [
  {
    id: "1",
    heading: "Electronic Devices & Circuits Lab",
    "sub-head":
      "",
    pic: ece_edc_lab,
    link:"https://youtu.be/ZfA004WzxEU",
    content_1:
      "",
    content_2:
      "The Electronic Devices and Circuits Laboratory has been established over an area of 159 sq.m. Here courses such as Digital Electronics Lab for III Sem, Linear Integrated Circuits Lab for V Sem and Electronic Circuits & Simulation Lab for IV Sem, are conducted. This lab is equipped with Electronic Workbenches with Regulated  Power supply, Function Generator, Cathode Ray Oscilloscope, Micro ammeter and Voltmeter, Bread Board and Personal computer with Mulitsim and ORCAD PSPICE Perpetual License (Software) and Digital trainer Kits.",
  },
  {
    id: "2",
    heading: "Analog & Digital Communication Lab",
    "sub-head":
      "",
    pic: ece_adc_lab,
    link:"https://youtu.be/_S7TKURJSlI",
    content_1:
    "",
    content_2:
      "The Analog & Digital Communication Lab has an area about  159  sq.m. Here courses such as Electron Devices and Circuits lab for III Sem, Digital Electronics and  Communication Lab for III Sem CSE, Analog and  Digital Communication  Lab for VI Sem  are conducted. This lab is equipped with Digital Storage Oscilloscopes, Advanced Motorized antenna Set up to determine the radiation characteristics of Loop antenna, dipole antenna, Yagi-Uda antenna and Micro strip antennas, Communication Trainer Kits, Regulated Power supplies, Function Generators, Cathode Ray Oscilloscopes, Ammeters and Voltmeters.",
  },
  {
    id: "3",
    heading: "VLSI & Cyber Physical Systems Lab",
    "sub-head":
      "",
    pic: ece_vlsi_lab,
    link:"https://youtu.be/1CGh5oMUmu0",
    content_1:
      "",
    content_2:
      "VLSI & Cyber Physical Systems Lab has an area about 247.7 sq.m where  courses such as VLSI Lab for VI Sem, Cyber Physical Systems Lab for VII Sem, Microprocessor and Microcontrollers Lab for V Sem  are conducted. This lab is equipped with   FPGA kits, 8085 & 8086 Microprocessor Kits and 8051 Microcontroller kits, interfacing kits, Arduino Microcontrollers and various  sensors such as Ultrasonic sensors, Infrared sensors, Temperature sensors, LDR, LED and Photodiodes for doing Cyber Lab Experiments. This Lab is also equipped with Xilinx and  IC Design Software (CADENCE) for doing VLSI Lab experiments.",
  },
  {
    id: "4",
    heading: "Computer Networks Lab",
    "sub-head":
      "",
    pic: ece_cn_lab,
    link:"https://youtu.be/ceGhtogDQaQ",
    content_1:
      "",
    content_2:
      "The Network Programming Lab, tailored for VI-semester students, is a crucial component of our academic program. It is well-equipped with LAN Trainer kits for hands-on experience in Local Area Network management. Additionally, the lab features Network simulation software (NETSIM) to simulate Network routing protocols and Wireless Sensor Networks kits for undergraduate project work, enabling students to develop practical skills and conduct research in the field of network programming and management.",
  },
  {
    id: "5",
    heading: "RF & Microwave Electronics Lab",
    "sub-head":
      "",
    pic: ece_rfm_lab,
    link:"https://youtu.be/YsQiOEL3B84",
    content_1:
      "",
    content_2:
      "RF & Microwave Electronics Lab has an area of about 54.3 sq.m where the course  RF and Microwave Lab for VII Sem is conducted. This lab is equipped with Klystron Microwave Test Bench and Gunn Oscillator Microwave Test Bench for measuring the Frequency, Guided wave length, Power, Insertion loss and Coupling coefficient at Microwave frequency.",
  },
  {
    id: "6",
    heading: "Signal Processing Lab",
    "sub-head":
      "",
    pic: ece_sig_lab,
    link:"https://youtu.be/HKTqdZcdL30",
    content_1:
      "",
    content_2:
      "The Digital Signal Processing Lab, spanning 400 sq.m, serves as the venue for the V Semester's Digital Signal Processing Lab sessions. The lab is well-appointed with essential tools, including DSP Processors and PCs equipped with MATLAB simulation software. This setup enables students to delve into the practical aspects of digital signal processing, enhancing their understanding and skills in this critical field of study.",
  },
  {
    id: "8",
    heading: "Fiber Optic Networking Lab",
    "sub-head":
      "",
    pic: ece_fo_lab,
    link:"https://youtu.be/59r3J5tJ12k",
    content_1:
      "",
    content_2:
      "This Lab has  an  area of 32.5 sq.m where courses such as  Fiber Optic Networking  Lab for I Sem(M.E) and Optical communication and Networking Lab for VII Sem(B.E)  are conducted. This Lab is also used for Ph.D Research scholars. It is equipped with UV3000+ UV/VIS Spectro Photo Meter, E6000CI Single Mode OTDR, MS 9740A Optical Spectrum Analyzer, Light Runner and LASER Equipments.",
  },
  {
    id: "9",
    heading: "Optical Networking & Simulation Lab",
    "sub-head":
      "",
    pic: ece_opt_lab,
    link:"https://youtu.be/7lqgUOTra3I",
    content_1:
      "",
    content_2:
      "In this Lab Optical Networking  Lab for II Sem(M.E) is conducted. This Lab is also used by Project students of VIII Sem(B.E) and  II year (M.E). This Lab is equipped with OPTI System Perpetual License (Software) version 12,16,16.1, OPTSIM Perpetual License (Software) and Photonics CAD Perpetual License (Software).",
  },
  {
    id: "10",
    heading: "RF System Design Lab",
    "sub-head":
      "",
    pic: ece_rfs_lab,
    link:"https://youtu.be/VszECOgNqHg",
    content_1:
      "The RF System Validation Laboratory serves as a crucial resource for verifying RF systems designed by B.E, M.E, and Ph.D. scholars during their projects and research endeavors. Additionally, the lab is open for use by outstation Ph.D. scholars, PG scholars, and industry professionals at a nominal fee.",
    content_2:
      "It offers a range of measurement capabilities, including assessing the Return Loss, Transmission coefficient, Reflection coefficient, Gain of RF systems, and the input/output impedance of RF systems. The lab also enables the measurement of the Frequency Spectrum of single or composite signals, Phase Noise of RF oscillators, and the Constellation of digitally modulated signals. Equipped with advanced tools like Vector Network Analyzers, Spectrum Analyzers, and Signal Generators, as well as a variety of RF and Microwave Frequency Simulators, this facility empowers researchers and scholars to conduct in-depth RF system analysis and validation for both academic and industrial applications.",
  },
];

const ece_indus_labs = [
  {
    id: "1",
    heading: "PCB Prototyping Station",
    "sub-head":
      "",
    pic: ece_pcb_lab,
    link:"https://youtu.be/WRvpYQJ1VtY",
    content_1:
      "",
    content_2:
      "The PCB Prototyping Station, covering 11.16 sq.m., plays a pivotal role in enabling the fabrication of RF and Microwave components and antennas for projects undertaken by undergraduate, postgraduate students, and research scholars. This versatile facility supports the creation of two-layer PCBs, catering to both low and high-frequency electronic circuits. Equipped with a state-of-the-art PCB Prototyping Machine and a dedicated Soldering station, it empowers individuals to translate their theoretical designs into tangible prototypes. This hands-on approach fosters practical skills and innovation, enhancing the overall learning and research experience in the domains of electronics and telecommunications.",
  },
  {
    id: "2",
    heading: "Antenna Testing Centre",
    "sub-head":
      "",
    pic: ece_antenna_lab,
    content_1:
      "This Centre can measure the various radiation parameters such as Azimuth & Elevation Radiation Pattern / Co-Polarization, Cross Polarization, Gain, Radiation Efficiency, Directivity, Half Power Beam Width, Front to Back Ratio, Linear Polarization, Reflection coefficient (Return Loss), SWR, Transmission Parameter and Port Impedance Profile.",
    content_2:
      "The center is equipped with cutting-edge equipment, including a Vector Network Analyzer (100KHz - 20GHz) by Rohde & Schwarz, a Double Ridged Horn Antenna (1GHz - 18 GHz) with computational software in the Anechoic Chamber, and major RF equipment like Vector Network Analyzers, Spectrum Analyzers, Signal Generators, and more. It also features essential simulation software, including Advanced Design System (ADS), Ansys – Electronics Desktop, CST Studio Suite, and PathWave EM Design (EMPro).",
  },
  {
    id: "3",
    heading: "IC Testing Facility",
    "sub-head":
      "",
    pic: ece_ic_lab,
    content_1:
      "",
    content_2:
      "The Center of Excellence in Electronics Test Engineering serves as a valuable resource for B.E., M.E., and Ph.D. scholars conducting mini-projects, final-year projects, and research initiatives. Beyond its academic role, this center offers an opportunity for revenue generation by providing access to outstation Ph.D. scholars and industry professionals for testing electronic boards at a nominal fee. It thus bridges the gap between academia and industry, facilitating practical learning and fostering collaborative research endeavors in the field of electronics.",
  },
];

const ece_dept_lib = [
  {
    id: "1",
    head: "",
    house: "left",
    content:"TV, VLSI, ROBOTICS, TQM,PE & HVs",
    pdf:"https://drive.google.com/file/d/1kHfVDHTi_T5yHA1kzntvL3bMaw7bsZoJ/view?usp=drive_link"
  },
  {
    id: "2",
    head: "",
    house: "right",
    content:"SATELLITE COMMMUNICATION, RADAR, LIC, DIP",
    pdf:"https://drive.google.com/file/d/1CRdFa6dAP9LbxFgSL3LvajzjemGb3im3/view?usp=drive_link"
  },
  {
    id: "3",
    head: "",
    house: "left",
    content:"OPTICS, SIGNALS AND SYSTEMS",
    pdf:"https://drive.google.com/file/d/1dmtaz7Qmo671Nspe95w0GISO91pylEac/view?usp=drive_link"
  },
  {
    id: "4",
    head: "",
    house: "right",
    content:"MP & MC, M&I, MEDICAL ELECTRONICS",
    pdf:"https://drive.google.com/file/d/1c2OINbHfD1jNqcPPqM25kmWQAlsPGdTS/view?usp=drive_link"
  },
  {
    id: "5",
    head: "",
    house: "left",
    content:"EMF, TLW, MICROWAVE, ANTENNA",
    pdf:"https://drive.google.com/file/d/1gB26h5KP30jAaKyrDhemyrzoDqYt4Xi_/view?usp=drive_link"
  },
  {
    id: "6",
    head: "",
    house: "right",
    content:"Electronic Circuits",
    pdf:"https://drive.google.com/file/d/1e64hQIRWP2qfOVsrKRlLVAkAqJwHjBzg/view?usp=drive_link"
  },
  {
    id: "7",
    head: "",
    house: "left",
    content:"Digital Signal Processing",
    pdf:"https://drive.google.com/file/d/1IkCh-td-VoHNW0VPPJRfPUTroA3JBVAq/view?usp=drive_link"
  },
  {
    id: "8",
    head: "",
    house: "right",
    content:"Digital Electronics & Circuits",
    pdf:"https://drive.google.com/file/d/1UhefC14fD0EyU8cGMXHFFxaQkyg5hlfp/view?usp=drive_link"
  },
  {
    id: "9",
    head: "",
    house: "left",
    content:"Computer Networks & Control Systems",
    pdf:"https://drive.google.com/file/d/1UBeavd7cGGtX_xE3XGjjpdSSoTsNDrA8/view?usp=drive_link"
  },
  {
    id: "10",
    head: "",
    house: "right",
    content:"Communication Systems",
    pdf:"https://drive.google.com/file/d/1KLyoDU9zMFRdnOqLPWg9HVv0mSoQtrAR/view?usp=drive_link"
  },
  
];

const ece_dept_events = [
  {
    id: "13",
    head: "Faculty Development Programme “Artificial Inteligence”",
    house: "left",
    date:"20th DEC 2022",
    content:
      "",
  },
  {
    id: "12",
    head: "Short talk on “Expand your horizon”",
    house: "right",
    date:"1st NOV 2021",
    content:
      "",
  },
  {
    id: "11",
    head: "Intra Department Technical Symposium – STARWARTZ 2k21",
    house: "left",
    date:"13th MAR 2021",
    content:
      "",
  },
  {
    id: "10",
    head: "Intra Department Technical Symposium – Shnkrayaan 2k20",
    house: "right",
    date:"28th FEB 2020",
    content:
      "",
  },
  {
    id: "9",
    head: "Two weeks Faculty Development Programme Recent advances in Communication system",
    house: "left",
    date:"2nd - 15th DEC 2019",
    content:
      "",
  },
  {
    id: "8",
    head: "National Level Technical Symposium – PRAGYANZ’ 19",
    house: "right",
    date:"18th OCT 2019",
    content:
      "",
  },
  {
    id: "7",
    head: "Conference NCMOC 2019",
    house: "left",
    date:"25th MAR 2019",
    content:
      "",
  },
  {
    id: "6",
    head: "One week FDP on Recent Progress in Optical Communication Research",
    house: "right",
    date:"18th - 24th OCT 2018",
    content:
      "",
  },
  {
    id: "5",
    head: "National Level Technical Symposium – PRAGYANZ 2K18",
    house: "left",
    date:"26th & 27th SEP 2018",
    content:
      "",
  },
  {
    id: "4",
    head: "Two days workshop on “Recent trends in Arduino boards & systems”",
    house: "right",
    date:"16th & 17th AUG 2018",
    content:
      "",
  },
  {
    id: "3",
    head: "Conference NCMOC 2018",
    house: "left",
    date:"25th APR 2018",
    content:
      "",
  },
  {
    id: "2",
    head: "Three days workshop on “Mobile maintenance and servicing”",
    house: "right",
    date:"4th-6th APR 2018",
    content:
      "",
  },
  {
    id: "1",
    head: "Intra Department Technical Symposium – SIRUS’18",
    house: "left",
    date:"20th FEB 2018",
    content:
      "",
  },
];

const ece_events = [
  {
    id: "8",
    heading: "Training Programme in RP2040 Dual Core Embedded Controller",
    "sub-head":
      "2022-2023",
    pic: rp2040,
    content_1:
      "",
    content_2:
      "",
  },
  {
    id: "7",
    heading: "Seminar on Genomics Technology Era",
    "sub-head":
      "2023-2024",
    pic: genomics,
    content_1:
      "",
    content_2:
      "",
  },
  {
    id: "6",
    heading: "Festronix'23",
    "sub-head":
      "29th NOV 2023",
    pic: festronix_23,
    content_1:
      "",
    content_2:
      "",
  },
  {
    id: "4",
    heading: "Pragyanz'22",
    "sub-head":
      "20th APR 2022",
    pic: prag22,
    content_1:
      "",
    content_2:
      "",
  },
  {
    id: "5",
    heading: "Workshop on modern semi-con devices & PCBAs",
    "sub-head":
      "19th MAR 2022",
    pic: unknown,
    content_1:
      "",
    content_2:
      "",
  },
  {
    id: "2",
    heading: "Stalwartz 2k21",
    "sub-head":
      "13th MAR 2021",
    pic: stal21,
    content_1:
      "",
    content_2:
      "",
  },
  {
    id: "3",
    heading: "Pragyanz'19",
    "sub-head":
      "18th OCT 2019",
    pic: prag21,
    content_1:
      "",
    content_2:
      "",
  },
  {
    id: "1",
    heading: "Conference NCMOC 2019",
    "sub-head":
      "25th MAR 2019",
    pic: ncmoc19,
    content_1:
      "",
    content_2:
      "",
  },
];
const ece_projects = [
  {
    id: "1",
    heading: "One Week Training on Robotics",
    "sub-head":
      "Some representative placeholder content for the second slide of the carousel.",
    pic: robotics,
    content_1:
      "Our Commitment as a Centre of Engineering Education is to impart Technical Knowledge par excellence, motivate the learners in Research, evolve result-oriented, innovative techniques in Engineering, provide necessary career guidance, and train our learners" +
      "in leadership qualities so as to achieve better productivity and prosperity for our country.",
    content_2:
      "ACGCET, a leader in higher education in engineering and technology, is committed to excellence through the delivery of outstanding undergraduate and graduate education that remains current and responsive to the diverse needs of those it serves. The institution’s" +
      "faculty and staff promote the intellectual, professional, social, and personal development of its students through innovations in learning, scholarship, and creative endeavours. Students, faculty, and staff partner to create strong" +
      "engagement with the local, national, and global communities. The institution dedicates itself to academic vitality, integrity, and diversity.",
  },
  {
    id: "2",
    heading: "Placement Activities",
    "sub-head":
      "Some representative placeholder content for the second slide of the carousel.",
    pic: placement,
    content_1:
      "Our Commitment as a Centre of Engineering Education is to impart Technical Knowledge par excellence, motivate the learners in Research, evolve result-oriented, innovative techniques in Engineering, provide necessary career guidance, and train our learners" +
      "in leadership qualities so as to achieve better productivity and prosperity for our country.",
    content_2:
      "ACGCET, a leader in higher education in engineering and technology, is committed to excellence through the delivery of outstanding undergraduate and graduate education that remains current and responsive to the diverse needs of those it serves. The institution’s" +
      "faculty and staff promote the intellectual, professional, social, and personal development of its students through innovations in learning, scholarship, and creative endeavours. Students, faculty, and staff partner to create strong" +
      "engagement with the local, national, and global communities. The institution dedicates itself to academic vitality, integrity, and diversity.",
  },
];

const ece_cocurricular_data = [
  {
    id:1,
    program:'2022-23',
    pdf:'https://drive.google.com/file/d/1MReCIeYLWTd4PPMib9RU73rfKCODYV1R/view?usp=sharing',
  }
]

const ece_extracurricular= [
  {
    id:1,
    program:'2023-24',
    pdf:'https://drive.google.com/file/d/1XI2xR4XmRiZ_DYToY7dNTgPPBcx2uyio/view?usp=sharing',
  }
]



const ece_stud_activities = [
  {
    id: "20",
    heading: "Anna University Zonal Handball'24",
    "sub-head":
      "S.Shahid Affan participated and secured 3rd position in the Zonal handball tournament held at Chenduran College Of Engineering and Technology",
    pic: handball,
    content_1: "",
    content_2: "",
  },
  {
    id: "19",
    heading: "Anna University Zonal Football'24",
    "sub-head":
      "Rajavarman.A, secured runnerup position in the Zonal Football tournament held at Mount Zion College of Engineering and Technology",
    pic: football,
    content_1: "",
    content_2: "",
  },
  {
    id: "18",
    heading: "Anna University Zonal Basket Ball'24",
    "sub-head":
      "Sujitha S, Janani Kamachi.S and Durga.A  secured 3rd position in the Zonal Basket ball tournament held at Vellamal College of Engineering and Technology,Madurai.",
    pic: basketball,
    content_1: "",
    content_2: "",
  },
  {
    id: "19",
    heading: "Top Designers 2024",
    "sub-head":
      "Sowmiya D ,Vasanthamalar J and Blesslin Rinu P bagged 1st place in Top Designers contest conducted by Sudharsan Engineering College.",
    pic: top_design1_24,
    content_1: "",
    content_2: "",
  },
  {
    id: "18",
    heading: "Top Designers 2024",
    "sub-head":
      " Geethapriya,Nidya Sri kamatchi and Nandhini bagged 2nd place in Top Designers contest conducted by Sudharsan Engineering College.",
    pic: top_design2_24,
    content_1: "",
    content_2: "",
  },
  {
    id: "17",
    heading: "Ad-shoot event",
    "sub-head":
      "Arun Kumar N and Akash P of '25 batch has secured the first prize in the Ad-shoot event of UTSAVAM-24 organized by Alagappa Institute of management held on 15th March 2024",
    pic: arunkumar,
    content_1: "",
    content_2: "",
  },
  {
    id: "19",
    heading: "Sivakumar M",
    "sub-head":
      "Sivakumar M of 26 batch secured the 1st place in 400m and 2nd place in 110m in the State Level Athletics Meet 2024 held at Tirunelveli",
    pic: sivakumar,
    content_1: "",
    content_2: "",
  },
  {
    id: "18",
    heading: "Vimanthan KG",
    "sub-head":
      "VimanthanK G, Anna University captain for Southzone National Level Archery competition which was held at Punjab on December2023. He secured 198th rank at all India level.",
    pic: vimanthan,
    content_1: "",
    content_2: "",
  },
  {
    id: "17",
    heading: "Thiran 2023",
    "sub-head":
      "S.Thevi Srinidhi & J.Joselin Shinita bagged Squadron Leader Victoria Cecil award in Thiran 2k23.",
    pic: jos_thiran_23,
    content_1: "",
    content_2: "",
  },
  {
    id: "16",
    heading: "Thiran 2023",
    "sub-head":
      "Mohamed Mujahith P, Sowndhirarajan P & Aatheshwaran S bagged 1st place in Thiran 2k23.",
    pic: mm_thiran_23,
    content_1: "",
    content_2: "",
  },
  {
    id: "15",
    heading: "Thiran 2023",
    "sub-head":
      "Divya Barathi S, Gokul R & Dharaneesh K bagged 2nd place in Thiran 2k23.",
    pic: gok_thiran_23,
    content_1: "",
    content_2: "",
  },
  {
    id: "14",
    heading: "Thiran 2023",
    "sub-head":
      "Malaiarasan P & Akash P bagged 3rd place in Thiran 2k23.",
    pic: malai_thiran_23,
    content_1: "",
    content_2: "",
  },
  {
    id: "7",
    heading: "Top Designers 2023",
    "sub-head":
      "S.Kavya, K.Thiviyadarssini & V.Sowmiya bagged 1st place in Top Designers contest conducted by Sudharsan Engineering College.",
    pic: top_design1,
    content_1: "",
    content_2: "",
  },
  {
    id: "8",
    heading: "Top Designers 2023",
    "sub-head":
      "Divya barathi, S.Lavanya & M.Arthi bagged 3rd place in Top Designers contest conducted by Sudharsan Engineering College.",
    pic: top_design2,
    content_1: "",
    content_2: "",
  },
  {
    id: "6",
    heading: "Top Designers 2023",
    "sub-head":
      "R.Tharini, K.Rishika & T.Mhadevi bagged 4th place in Top Designers contest conducted by Sudharsan Engineering College.",
    pic: circuit_designers,
    content_1: "",
    content_2: "",
  },
  {
    id: "10",
    heading: "Top Designers 2k22",
    "sub-head": "Aswin S, Dharaneesh K, Dinesh kumar R bagged 1st place in Top Designers contest conducted by Sudharsan Engineering College.",
    pic: asw_top_design_2k22,
    content_1: "",
    content_2: "",
  },
  {
    id: "9",
    heading: "Top Designers 2k22",
    "sub-head": "P Hema, R Vishalini devi & S Hari rama krishnan bagged 2nd place in Top Designers contest conducted by Sudharsan Engineering College.",
    pic: hrk_top_design_2k22,
    content_1: "",
    content_2: "",
  },
  {
    id: "13",
    heading: "Top Designers 2k22",
    "sub-head": "Anusha K, Thiviyadarshini K, Vidhya S bagged 3rd place in Top Designers contest conducted by Sudharsan Engineering College.",
    pic: anu_top_design_2k22,
    content_1: "",
    content_2: "",
  },
  {
    id: "11",
    heading: "Top Designers 2k22",
    "sub-head": "V sowmiya, K Rishika,M Arthi bagged 2nd place in Top Designers contest conducted by Sudharsan Engineering College.",
    pic: sow_top_design_2k22,
    content_1: "",
    content_2: "",
  },
  {
    id: "12",
    heading: "Paper Presentation",
    "sub-head": "Malaiarasan P and Abhimanyu P bagged 1st place in Paper presentation contest in St.Michael Engineering College.",
    pic: paper_presentation,
    content_1: "",
    content_2: "",
  },
  {
    id: "4",
    heading: "Technical Quiz-St.Michael",
    "sub-head": "J Anuraj kishore & S Aswin has bagged the 1st place in the Technical Quiz contest in St.Michael Engineering College.",
    pic: tech_quiz,
    content_1: "",
    content_2: "",
  },
  {
    id: "3",
    heading: "Best Manager",
    "sub-head":
      "J Anuraj kishore has bagged the best manager award in Hindustan Engineering College event.",
    pic: best_manager,
    content_1: "",
    content_2: "",
  },
  {
    id: "2",
    heading: "Circuit Debugging",
    "sub-head":
      "S.Sushmitha bagged 1st place in Circuit Debugging contest conducted by GCE Srirangam.",
    pic: circuit_debug,
    content_1: "",
    content_2: "",
  },
  {
    id: "1",
    heading: "Thiran 2k22",
    "sub-head":
      "Gokulakrishnan G bagged 2nd place in Thiran 2k22 with the project 'Wifi Automation' built with a team of 4.",
    pic: thiran_2k22,
    content_1: "",
    content_2: "",
  },
];

const ece_antenna_software = [
  {
    id: "1",
    point: "Advanced Design System (ADS)",
  },
  {
    id: "2",
    point: "Ansys – Electronics Desktop",
  },
  {
    id: "3",
    point: "CST Studio Suite",
  },
  {
    id: "4",
    point: "PathWave EM Design (EMPro)",
  },
];

const ece_antenna_anechoic = [
  {
    id: "1",
    point: "Vector Network Analyzer (100KHz - 20GHz) – Rohde & Schwarz make",
  },
  {
    id: "2",
    point:
      "Double Ridged Horn Antenna (1GHz - 18 GHz) and computational software",
  },
];

const ece_antenna_rf = [
  {
    id: "1",
    point: "Vector Network Analyzer (100KHz - 20GHz) – Rohde & Schwarz make",
  },
  {
    id: "2",
    point: "Vector Network Analyzer (300KHz - 3GHz) - Agilent make",
  },
  {
    id: "3",
    point: "Spectrum Analyzer (5KHz-7.5GHz) – Rohde & Schwarz make",
  },
  {
    id: "4",
    point: "Spectrum Analyzer (9KHz-3GHz) – Agilent make",
  },
  {
    id: "5",
    point: "Vector Signal Generator (250KHz-3GHz) – Agilent make",
  },
  {
    id: "6",
    point: "Mixed Signal Oscilloscope (300MHz) – Agilent make",
  },
  {
    id: "7",
    point: "Power Sensor (10MHz -18GHz, -30dBm to 20dBm)",
  },
];
const list_head =[
  {
    id: "1",
    point: "Establish new course on Electronic testing and thereby make the students get hands on training in the respective domain",
  },
  {
    id: "2",
    point: "Conduct high Quality Training in Electronic testing",
  },
  {
    id: "3",
    point: "Develop association between academia and industry related to Electronic testing",
  },
  {
    id: "4",
    point: "Conduct Research and Development in related fields and disseminate the results into sustainable business proposals / solutions",
  },
  {
    id: "5",
    point: "Upgrade the Centre’s technical capacity ",
  },
  {
    id: "6",
    point: "Support creative and innovative proposals in terms of functions and facilities",
  },
  

];
const list_head1=[
  {
    id: "1",
    point: "Test Director 9 - Simulation platform",
  },
  {
    id: "2",
    point: "Board walker 9627",
  },
  {
    id: "3",
    point: "Boundary Scan Test kit",
  },
  {
    id: "4",
    point: "QT 55 - VI Trainer Kit",
  },
  {
    id: "5",
    point: "QT - 65 - ICFT and OCFT Kit",
  },
  {
    id: "6",
    point: "Nodal Impedance Analyser",
  },
  {
    id: "7",
    point: "PICO Oscilloscope Diagnostics",
  },
  {
    id: "8",
    point: "LCR Meter",
  },

]

const ece_placement_info = [
  {
    id:"4",
    year: "2020-2024 Batch",
    salary:"7 LPA",
    details: [
      {
        id: "1",
        heading: "In-Campus",
        perc: 54,
        symbol:"%"
      },
    ],
  },
  {
    id:"1",
    year: "2019-2023 Batch",
    salary:"8 LPA",
    details: [
      {
        id: "1",
        heading: "In-Campus",
        perc: 59,
        symbol:"%"
      },
    ],
  },
  {
    id:"2",
    year: "2018-2022 Batch",
    salary:"5.6 LPA",
    details: [
      {
        id: "1",
        heading: "In-Campus",
        perc: 69,
        symbol:"%"
      },
      {
        id: "2",
        heading: "Higher Studies",
        perc: 2,
        symbol:""
      },
    ],
  },
  {
    id:"3",
    year: "2017-2021 Batch",
    salary:"5 LPA",
    details: [
      {
        id: "1",
        heading: "In-Campus",
        perc: 72,
        symbol:"%"
      },
      {
        id: "2",
        heading: "Higher Studies",
        perc: 3,
        symbol:""
      },
      {
        id: "2",
        heading: "Entrepreneur",
        perc: 1,
      },
    ],
  },
];

const ece_intern=[
  
  {
    'id':'1',
    'program':'Internship 2023-2024',
    'pdf':'https://drive.google.com/file/d/1soWf_av3Z3N1X153idf4wcKaAUq4f38U/view?usp=sharing '
},
{
  'id':'2',
  'program':'Internship 2022-2023',
  'pdf':'https://drive.google.com/file/d/18k11n_gx0ECOts0JPUwKKyFuLab3uLYz/view?usp=drive_link '
},
{
      'id':'3',
      'program':'Internship 2021-2022',
      'pdf':'https://drive.google.com/file/d/13e9q9mAH0vHu5UIrB2SA_sZSZlFHDo8Q/view?usp=drive_link '
  },
  
  {
    'id':'2',
    'program':'Internship 2021-2022',
    'pdf':'https://drive.google.com/file/d/13e9q9mAH0vHu5UIrB2SA_sZSZlFHDo8Q/view?usp=drive_link '
  },
  {
      'id':'1',
      'program':'Implant Training Details',
      'pdf':'https://drive.google.com/file/d/1PBZXDsAeY1cloa90xuBkya9aPumOdm8w/view?usp=drivesdk'
  },
]

  export const sympo_data=[
    {
        'id':1,
        'program':'Sygnels - A state level technical symposium',
        'pdf':'https://docs.google.com/document/d/1RAFv8A4BjETBTg66k_Zn4Rgzh0jDE4JD/edit?usp=sharing&ouid=108455310361181583387&rtpof=true&sd=true',
    },
]

export {
  ece_dept_menu,
  ece_fac_profiles,
  ece_sup_profiles,
  ece_vision,
  ece_mission,
  ece_peo,
  ece_outcomes,
  ece_progs,
  ece_acad_labs,
  ece_indus_labs,
  ece_rankers,
  ece_alumni,
  ece_alumni91,
  ece_alumni92,
  ece_alumni93,
  ece_notablealumni,
  ece_dept_events,
  ece_projects,
  ece_stud_activities,
  ece_antenna_anechoic,
  ece_antenna_rf,
  ece_antenna_software,
  ece_hod,
  ece_research_info,
  patent_info,
  guide_info,
  scholar_info1,
  scholar_info2,
  scholar_info3,
  scholar_info4,
  ece_siva,
  ece_margaret,
  ece_rajesh,
  ece_placement_info,
  ece_stud_data,
  ece_dept_lib,
  ece_events,
  ece_spec_outcomes,
  ece_intern,
  ece_cocurricular_data,
  ece_extracurricular,
  ece_karthika,
  list_head,
  list_head1

};
