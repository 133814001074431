export const about_floater_menu={
    'head':'In this Section:',
    'links':[
        {
            'id':'1',
            'link':'Why ACGCET',
            'path':'#why_acgcet',
            'arl':'a'
        },
        {
            'id':'2',
            'link':'History',
            'path':'#history',
            'arl':'a'
        },
        {
            'id':'5',
            'link':'Visit ACGCET',
            'path':'#vist_acgcet',
            'arl':'a'
        },
        {
            'id':'4',
            'link':'Campus Map',
            'path':'#campus_map',
            'arl':'a'
        },
    ]
}